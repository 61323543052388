import { IFaqHint } from "@api/faq-hints/schema"
import { GradientBackground } from "@components/atoms/gradient"
import { AnimatePresence, motion } from "framer-motion"

type IChatHints = {
    hints: IFaqHint[]
    onClick: (message: string) => void
}

export function ChatHints(props: IChatHints) {
    const { hints, onClick } = props
    return (
        <AnimatePresence>
            <div className="grid grid-cols-[repeat(auto-fit,minmax(30ch,1fr))] gap-4">
                {hints.map((hint, idx) => (
                    <motion.div
                        key={hint.id}
                        className="grid grid-cols-[repeat(auto-fit,minmax(30ch,1fr))] gap-4"
                        initial={{ y: "50%", opacity: 0 }}
                        animate={{ y: "0%", opacity: 1 }}
                        transition={{ delay: 0.2 * idx }}
                    >
                        <GradientBackground
                            variant="dark"
                            className="gap-4 p-4 text-textVeryLight"
                            radius="big"
                            onClick={() => onClick(hint.question)}
                        >
                            {hint.question}
                        </GradientBackground>
                    </motion.div>
                ))}
            </div>
        </AnimatePresence>
    )
}
