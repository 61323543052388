import { z } from "zod"

export const KpisSchema = z.object({
    endenergie: z.number().nullable(),
    primaerenergie: z.number().nullable(),
    co2: z.number().nullable(),
    effizienzklasse: z.string().nullable(),
})

export type IKpis = z.infer<typeof KpisSchema>
