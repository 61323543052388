import { Device } from "@capacitor/device"

export async function isMobile(): Promise<boolean> {
    const { iOSVersion, androidSDKVersion } = await Device.getInfo()
    if (iOSVersion || androidSDKVersion) {
        return true
    } else {
        return false
    }
}
