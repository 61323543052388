import { handleFetch, apiUrl, queryClient } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useMutateDataPaths(queryKey: string, projectId: number) {
    const authHeader = useAuthHeader()

    return useMutation({
        mutationFn: async (data: FormData) => {
            if (!authHeader) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/project/set-paths`, {
                    method: "put",
                    body: JSON.stringify({
                        data: {
                            projectId,
                            paths: Object.fromEntries(data),
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gespeichert")

            await queryClient.invalidateQueries({
                queryKey: [queryKey],
            })
        },
    })
}
