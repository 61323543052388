import { handleFetch, apiUrl } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseDataPaths = {
    enabled?: boolean
}

export function useDataPaths(queryKey: string, projectId: number, paths: string[], params?: IUseDataPaths) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        placeholderData: [],
        queryKey: [queryKey],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/project/get-paths`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                    body: JSON.stringify({
                        projectId,
                        paths,
                    }),
                })
            )

            return data
        },
        enabled: !!enabled && !!authHeader,
    })
}
