import { IRegister, register } from "@api/auth/register.ts"
import { Button } from "@components/atoms/buttons"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import * as Form from "@radix-ui/react-form"
import { motion } from "framer-motion"
import React from "react"
import { useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { toast } from "sonner"

export function Register() {
    const form = React.useRef<HTMLFormElement | null>(null)
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(false)

    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/auth/register-selection",
        showContextMenu: false,
    })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        if (!form.current) {
            return
        }

        try {
            setLoading(true)
            const formData = new FormData(form.current)
            const registerData = Object.fromEntries(formData) as IRegister

            await register(registerData)
            form.current?.reset()
            navigate("/auth/login-selection")
            toast.info("Bitte bestätige deine E-Mail Addresse bevor wir weitermachen.")
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[22rem] 2xl:w-[40rem] "
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-end gap-12 px-8 pt-20 lg:content-center">
                <motion.div className="grid gap-4" layoutId="register-title">
                    <Heading level="h1">Registrieren</Heading>
                    <Text variant="body1" className="text-textLight">
                        Nur noch eben einen Account anlegen, dann kann es direkt losgehen.
                    </Text>
                </motion.div>

                <Form.Root onSubmit={onSubmit} ref={form} className="grid gap-16">
                    <motion.div
                        transition={{
                            type: "tween",
                            ease: "circInOut",
                        }}
                        style={{ originX: 0.5, originY: 1 }}
                        initial={{
                            scaleY: 0.75,
                            transform: "translateY(25%)",
                            opacity: 0,
                        }}
                        animate={{
                            scaleY: 1,
                            transform: "translateY(0%)",
                            opacity: 1,
                        }}
                        className="grid gap-6"
                    >
                        <Input
                            name="email"
                            label="E-Mail"
                            type="text"
                            required
                            messages={[
                                {
                                    content: "E-Mail Adresse benötigt",
                                    match: "valueMissing",
                                },
                                {
                                    content: "E-Mail Adresse ungültig",
                                    match: "typeMismatch",
                                },
                            ]}
                        />
                        <Input
                            name="password"
                            label="Passwort"
                            type="password"
                            required
                            minLength={6}
                            messages={[
                                {
                                    content: "Mindestens 6 Zeichen benötigt",
                                    match: "tooShort",
                                },
                                {
                                    content: "Passwort benötigt",
                                    match: "valueMissing",
                                },
                            ]}
                        />
                    </motion.div>
                    <Form.Submit asChild>
                        <Button variant="primary" loading={loading}>
                            Registrieren
                        </Button>
                    </Form.Submit>
                </Form.Root>

                <Footer>
                    <Text variant="small" className="text-textLight">
                        Du hast bereits einen Account?
                    </Text>
                    <Link to="/auth/login-selection" className="text-white underline">
                        Login
                    </Link>
                </Footer>
            </div>
        </>
    )
}
