import { handleFetch, apiUrl } from "@api/apiConfig"

export type IForgot = {
    email: string
}

export async function forgotPassword(params: IForgot) {
    const { email } = params
    await handleFetch(() =>
        fetch(`${apiUrl}/auth/forgot-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
            }),
        })
    )
}
