import { apiUrl } from "@api/apiConfig"
import { LinkButton } from "@components/atoms/buttons/link-button.tsx"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"

export function RegisterSelection() {
    useTitlebar({
        title: "",
        mode: "hide",
        showContextMenu: false,
    })

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-15%] lg:top-[-20%] z-[-1] aspect-square w-3/4 justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-end gap-12 px-8 pt-12 lg:content-center">
                <motion.div className="grid gap-2" layoutId="register-title">
                    <Heading level="h1">Registrieren</Heading>
                    <Text variant="body1" className="text-textLight">
                        Nur noch eben einen Account anlegen, dann kann es direkt losgehen.
                    </Text>
                </motion.div>

                <div className="grid grid-cols-3 gap-4">
                    <LinkButton variant="primary" href="/auth/register" className="col-span-full">
                        E-Mail
                    </LinkButton>
                    <LinkButton variant="secondary" href={`${apiUrl}/connect/google`}>
                        <i className="ri-google-fill text-[1.5rem]"></i>
                    </LinkButton>
                    <LinkButton variant="secondary" href={`${apiUrl}/connect/facebook`}>
                        <i className="ri-facebook-fill text-[1.5rem]"></i>
                    </LinkButton>
                    <LinkButton variant="secondary" href={`${apiUrl}/apple-signin-provider/connect`}>
                        <i className="ri-apple-fill text-[1.5rem]"></i>
                    </LinkButton>
                </div>

                <Footer>
                    <Text variant="small" className="text-textLight">
                        Du hast bereits einen Account?
                    </Text>
                    <Link to="/auth/login-selection" className="text-white underline">
                        Login
                    </Link>
                </Footer>
            </div>
        </>
    )
}
