import { toast } from "sonner"
import { StorageError, StorageErrorType } from "./storage"

enum LidarPluginErrorType {
    PluginError = "PluginError",
    ParseError = "ParseError",
    IdentifierDoesNotExist = "IdentifierDoesNotExist",
    InvalidOperation = "InvalidOperation",
    UnsupportedPlatform = "UnsupportedPlatform", // Invalid Device (Android, Web)
    UnsupportedVersion = "UnsupportedVersion", // Invalid IOS
}

class LidarPluginError extends Error {
    type: LidarPluginErrorType
    constructor(message: string, type: LidarPluginErrorType) {
        super(message)
        this.name = "LidarPluginError"
        this.type = type
    }
}
const handleError = (err: LidarPluginError | StorageError): boolean => {
    /// 08.07.2024
    ///TODO: later we can improve this error handling to send the error to the server
    console.error(`Error Type: ${err.type}, Message: ${err.message}`)
    if (err instanceof StorageError) {
        return handleStorageError(err)
    } else {
        return handleLidarPluginError(err)
    }
}

function handleLidarPluginError(err: LidarPluginError | StorageError): boolean {
    switch (err.type) {
        case LidarPluginErrorType.IdentifierDoesNotExist:
            toast.error("Interner Error: Die ID existiert nicht")
            break
        case LidarPluginErrorType.ParseError:
            toast.error("Interner Error: Die Daten konnten nicht geparst werden")
            break
        case LidarPluginErrorType.PluginError:
            toast.error("Interner Error: Ein Fehler im Nativen-Plugin ist aufgetreten")
            break
        case LidarPluginErrorType.InvalidOperation:
            toast.error("Interner Error: Der Vorgang ist nicht gültig")
            break
        case LidarPluginErrorType.UnsupportedPlatform:
            toast.error("Das Gerät wird nicht unterstützt")
            break
        case LidarPluginErrorType.UnsupportedVersion:
            toast.error("Die Version wird nicht unterstützt")
            break
        default:
            toast.error("Interner Error: Ein unbekannter Fehler ist aufgetreten")
            break
    }
    return false
}

function handleStorageError(err: StorageError): boolean {
    switch (err.type) {
        case StorageErrorType.IdentifierDoesNotExist:
            toast.error("Interner Error: Die ID existiert nicht")
            break
        case StorageErrorType.InternalStorageError:
            toast.error("Interner Error: Ein Fehler im Storage ist aufgetreten")
            break
        case StorageErrorType.ParseError:
            toast.error("Interner Error: Die Daten konnten nicht geparst werden")
            break
        default:
            toast.error("Interner Error: Ein unbekannter Fehler ist aufgetreten")
            break
    }
    return false
}

export { LidarPluginError, LidarPluginErrorType, handleError }
