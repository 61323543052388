import * as Dialog from "@radix-ui/react-dialog"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"

type IPreviewImage = {
    path: string
    alt: string
    onRemove?: () => void
    allowPreview?: boolean
    className?: string
}

export function PreviewImage(props: IPreviewImage) {
    const { allowPreview = false, alt, className, path, onRemove } = props
    const [open, setOpen] = React.useState<boolean>(false)

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <div className={cls(className)}>
                <div className="relative">
                    <Dialog.Trigger asChild>
                        <button type="button" disabled={!allowPreview} className="h-24">
                            {!open && (
                                <motion.img
                                    alt={alt}
                                    src={path}
                                    layoutId={path}
                                    className={cls("aspect-square h-full rounded-lg object-cover", {
                                        "opacity-0": open,
                                    })}
                                />
                            )}
                        </button>
                    </Dialog.Trigger>
                    <AnimatePresence>
                        {open && (
                            <Dialog.Portal forceMount>
                                <div className="fixed inset-0 grid grid-rows-[minmax(0,1fr)]">
                                    <Dialog.Overlay forceMount asChild>
                                        <motion.div
                                            className="h-full w-full bg-background/50"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            style={{ gridArea: "1/1" }}
                                        ></motion.div>
                                    </Dialog.Overlay>
                                    <Dialog.Content asChild>
                                        <AnimatePresence>
                                            {open && (
                                                <motion.img
                                                    alt={alt}
                                                    src={path}
                                                    layoutId={path}
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                    className={cls(
                                                        "z-10 aspect-square h-5/6 place-self-center object-contain"
                                                    )}
                                                    style={{ gridArea: "1/1" }}
                                                    onClick={() => setOpen(false)}
                                                />
                                            )}
                                        </AnimatePresence>
                                    </Dialog.Content>
                                </div>
                            </Dialog.Portal>
                        )}
                    </AnimatePresence>
                    {onRemove ? (
                        <button
                            type="button"
                            className="absolute right-0 top-0 grid h-10 w-10 translate-x-[50%] translate-y-[-50%] place-content-center"
                            onClick={onRemove}
                        >
                            <i className="ri-close-circle-fill text-[1.2rem] text-primaryAccent2"></i>
                        </button>
                    ) : null}
                </div>
            </div>
        </Dialog.Root>
    )
}
