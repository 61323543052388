import { Camera, CameraResultType, Photo } from "@capacitor/camera"
import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import { useInputFocus } from "@hooks/useInputFocus"
import * as Form from "@radix-ui/react-form"
import * as Popover from "@radix-ui/react-popover"
import { cls } from "@utils"
import React from "react"

type IErrors = {
    messages?: Form.FormMessageProps[]
}

function Errors(props: IErrors) {
    const { messages } = props

    if (!messages?.length) {
        return null
    }

    return (
        <Form.ValidityState>
            {(validity) => {
                if (validity && !validity.valid) {
                    return (
                        <Popover.Root>
                            <Popover.Trigger className="leading-none">
                                <i className="ri-spam-fill text-[1.5rem] text-secondaryAccent1"></i>
                            </Popover.Trigger>
                            <Popover.Portal>
                                <Popover.Content
                                    side="top"
                                    sideOffset={10}
                                    align="end"
                                    className="z-40 rounded-2xl border-2 border-secondaryAccent1 bg-solidTransparentWarning px-6 py-2 text-secondaryAccent1"
                                >
                                    {messages?.map((message, idx) => {
                                        const { match, content } = message
                                        return (
                                            <Form.Message match={match} key={idx}>
                                                {content}
                                            </Form.Message>
                                        )
                                    })}
                                </Popover.Content>
                            </Popover.Portal>
                        </Popover.Root>
                    )
                }
            }}
        </Form.ValidityState>
    )
}

export const Radius = {
    small: "rounded-lg",
    big: "rounded-3xl",
    full: "rounded-full",
}

export type IInput = React.ComponentProps<"input"> & {
    name: string
    label?: string
    messages?: Form.FormMessageProps[]
    radius?: keyof typeof Radius
}

export function Input(props: IInput) {
    const { name, label, messages, radius = "small", ...rest } = props
    const inputRef = useInputFocus()

    return (
        <Form.Field name={name} className="grid gap-2.5">
            {label && (
                <div className="flex h-[1.5rem] items-end justify-between">
                    <Form.Label className="font-label text-label text-white">{label}</Form.Label>
                    <Errors messages={messages} />
                </div>
            )}

            <GradientBorder variant="dark" radius={radius}>
                <Form.Control
                    asChild
                    className={cls(
                        `gradient-border-light relative bg-backgroundLight py-3 text-body2 text-text focus-visible:outline-none
                        focus-visible:outline-offset-0 data-[invalid]:text-secondaryAccent1 data-[invalid]:outline data-[invalid]:outline-1
                        data-[invalid]:outline-secondaryAccent1/30`,
                        Radius[radius],
                        {
                            "px-4": radius === "small",
                            "px-6": radius !== "small",
                        }
                    )}
                >
                    <input {...rest} autoCapitalize="off" autoCorrect="off" ref={inputRef} />
                </Form.Control>
            </GradientBorder>
        </Form.Field>
    )
}

type IFileInput = {
    value: string
    onChange: (newValue: Photo) => void
}

export function FileInput(props: IFileInput) {
    const { value, onChange } = props

    const captureImage = async () => {
        const photo = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,
            webUseInput: true,
        })

        onChange(photo)
    }

    return (
        <div>
            {value ? <img src={value} /> : null}
            <Button variant="tertiary" type="button" onClick={captureImage}>
                <i className="ri-attachment-line text-[1.5rem]"></i>
            </Button>
        </div>
    )
}
