import { ListItem } from "@components/molecules/list-item"

interface IItemList {
    onEdit?: (id: string) => void
    onDelete?: (id: string) => void
    title: string
    subTitle?: string
    items: { value: string; title: string; id: string }[]
    icon: React.ReactNode
}

function ItemList({ onEdit, onDelete, title, subTitle, items }: IItemList) {
    return (
        <div className="font-label">
            <ul>
                <li>
                    <div className="mb-3 flex flex-col items-center gap-1">
                        <h5 className="text-center text-tiny font-light">{subTitle}</h5>
                        <h2 className="font-label text-body2">{title}</h2>
                    </div>
                    <div className="flex flex-col gap-4">
                        {items.map(({ value, title, id }) => (
                            <ListItem
                                key={id}
                                value={value}
                                title={title}
                                onEdit={() => onEdit && onEdit(id)}
                                onDelete={() => onDelete && onDelete(id)}
                                icon={<i className="ri-edit-box-line text-body1"></i>}
                            />
                        ))}
                    </div>
                </li>
            </ul>
        </div>
    )
}
export { ItemList }
