import { cls } from "@utils"
import { PropsWithChildren } from "react"
import { Link } from "react-router-dom"
import { Variant, Radius, variants } from "."

type ILinkButton = PropsWithChildren<{
    href: string
    variant: Variant
    className?: string
    isExternal?: boolean
    onClick?: () => void
    radius?: keyof typeof Radius
    download?: boolean
}>

export function LinkButton(props: ILinkButton) {
    const { children, variant, className, href, isExternal, onClick, radius, download } = props

    if (isExternal) {
        return (
            <a
                className={cls(variants[variant], radius ? Radius[radius] : "rounded-lg", className)}
                href={href}
                onClick={onClick}
                download={download}
                target="_blank"
            >
                {children}
            </a>
        )
    }

    return (
        <Link
            className={cls(variants[variant], radius ? Radius[radius] : "rounded-lg", className)}
            to={href}
            onClick={onClick}
        >
            {children}
        </Link>
    )
}
