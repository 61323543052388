enum StorageErrorType {
    ParseError = "ParseError",
    IdentifierDoesNotExist = "IdentifierDoesNotExist",
    InternalStorageError = "InternalStorageError",
}
class StorageError extends Error {
    type: StorageErrorType
    constructor(message: string, type: StorageErrorType) {
        super(message)
        this.name = "LidarPluginStorageError"
        this.type = type
    }
}

export { StorageError, StorageErrorType }
