import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"
import { ICertificateType } from "./schema"

export function useCreateCertificate(projectId: number, type: ICertificateType) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/energy-certificates`, {
                    method: "post",
                    body: JSON.stringify({
                        data: {
                            projectId,
                            type,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["certificate", projectId, type],
            })
            toast.success("Ausweise wird erstellt!")
        },
    })
}
