import { useMutateUser } from "@api/user/useMutateUser.ts"
import { useMutateUserAvatar } from "@api/user/useMutateUserAvatar.ts"
import { useMutateUserEmail } from "@api/user/useMutateUserEmail.ts"
import { useMutateUserPassword } from "@api/user/useMutateUserPassword.ts"
import { useRemoveUserAvatar } from "@api/user/useRemoveUserAvatar.ts"
import { useUser } from "@api/user/useUser.ts"
import { LoadingDots } from "@components/atoms/buttons/loading-dots.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { Logout } from "@components/molecules/logout.tsx"
import { ChangePassword, ChangeableImage, ChangeableText } from "@components/organisms/changeable-field.tsx"
import { DeleteAccount } from "@components/organisms/delete-account.tsx"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { capitalize } from "@utils"

export function Account() {
    const activeProjectId = useAppState((state) => state.activeProjectId)

    useTitlebar({
        title: "",
        mode: "back",
        backTo: `/authenticated/chat/${activeProjectId}`,
        showContextMenu: false,
    })

    const { isPending, data: account } = useUser()

    const mutation = useMutateUser()
    const avatarMutation = useMutateUserAvatar()
    const removeAvatarMutation = useRemoveUserAvatar()
    const emailMutation = useMutateUserEmail()
    const passwordMutation = useMutateUserPassword()

    const { id = 0, displayName, email, avatar, provider } = account ?? {}
    const isEmail = provider === "local"

    if (isPending) {
        return (
            <div className="grid h-full place-content-center">
                <LoadingDots />
            </div>
        )
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid max-w-screen-md content-start gap-12 p-8">
                <section className="grid gap-4">
                    <Heading level="h2">Konto</Heading>
                    <ChangeableImage
                        entryId={id}
                        value={avatar?.url ?? ""}
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Avatar",
                        }}
                        onMutate={async (data) => avatarMutation.mutateAsync({ data })}
                        onDelete={async () => removeAvatarMutation.mutateAsync({ userId: `${id}` })}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Name",
                        }}
                        inputProps={{
                            name: "displayName",
                            value: displayName ?? "---",
                            required: true,
                            messages: [
                                {
                                    content: "Name benötigt",
                                    match: "valueMissing",
                                },
                            ],
                        }}
                        onMutate={async (data) => mutation.mutateAsync({ id, data })}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: isEmail ? "edit" : "none",
                            label: isEmail ? "E-Mail" : `Eingeloggt mit ${capitalize(provider ?? "provider")}`,
                        }}
                        inputProps={{
                            name: "newEmail",
                            value: email,
                            disabled: !isEmail,
                            required: true,
                            messages: [
                                {
                                    content: "E-Mail benötigt",
                                    match: "valueMissing",
                                },
                            ],
                        }}
                        onMutate={async (data) => emailMutation.mutateAsync({ data })}
                    />
                    {isEmail && <ChangePassword onSubmit={async (data) => passwordMutation.mutateAsync({ data })} />}
                </section>

                {/* <section className="grid gap-6">
                <div className="grid gap-2">
                    <Heading level="h2">Zahlung</Heading>
                    <Text variant="body2" className="text-textVeryLight">
                        Füge eine Zahlungsmethode hinzu, um konstenpflichtige Angebote wahrzunehmen.
                    </Text>
                </div>
                <PaymentMethods
                    methods={[
                        {
                            iconClass: "ri-bank-card-2-fill",
                            name: "Visa",
                            ending: "789",
                        },
                    ]}
                />
            </section>

            <section className="grid gap-6">
                <div className="grid gap-2">
                    <Heading level="h2">Erweiterte Einstellungen</Heading>
                </div>
                <Switch
                    description="Ich bin Energieberater"
                    label="DIBt Login vorhanden"
                    name="isDibtUser"
                    defaultChecked={isDibtUser}
                    onCheckedChange={(checked) => {
                        const data = new FormData()
                        data.append("isDibtUser", `${checked}`)
                        mutation.mutate({ id, data })
                    }}
                />

                <AnimatePresence>
                    {isDibtUser && (
                        <motion.div
                            className="border-t-[1px] border-textVeryLight/40 pt-4"
                            style={{ originY: 0 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            <Heading level="h4">DIBt Login</Heading>
                            <ChangeableText
                                changeableFieldProps={{
                                    mode: "edit",
                                    label: "DIBt Username",
                                }}
                                inputProps={{
                                    name: "dibtUsername",
                                    value: dibtUsername ?? "",
                                    required: true,
                                    messages: [
                                        {
                                            content: "Name benötigt",
                                            match: "valueMissing",
                                        },
                                    ],
                                }}
                                onMutate={async (data) => mutation.mutate({ id, data })}
                            />
                            <ChangeableText
                                changeableFieldProps={{
                                    mode: "edit",
                                    label: "DIBt Password",
                                }}
                                inputProps={{
                                    name: "dibtPassword",
                                    value: dibtPassword ?? "",
                                    required: true,
                                    messages: [
                                        {
                                            content: "Name benötigt",
                                            match: "valueMissing",
                                        },
                                    ],
                                }}
                                onMutate={async (data) => mutation.mutate({ id, data })}
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </section> */}

                <section className="grid gap-6">
                    <Heading level="h2">Logout</Heading>
                    <Logout />
                </section>

                <section className="grid gap-6">
                    <div className="grid gap-2">
                        <Heading level="h2">Konto löschen</Heading>
                        <Text variant="body2" className="text-textVeryLight">
                            Wenn du deine Konto löschst, werden alle mit dir verbundenen Daten von unseren Servern
                            entfernt.
                        </Text>
                    </div>
                    <DeleteAccount />
                </section>
            </section>
        </ContentOffset>
    )
}
