import { Device } from "@capacitor/device"
import React from "react"

type Platform = "web" | "ios" | "android"

export function useDevicePlatform() {
    const [platform, setPlatform] = React.useState<Platform | null>(null)

    React.useEffect(() => {
        const getPlatform = async () => {
            const info = await Device.getInfo()
            setPlatform(info.platform)
        }

        void getPlatform()
    }, [])

    const isIos = () => platform === "ios"
    const isAndroid = () => platform === "android"
    const isWeb = () => platform === "web"

    return {
        platform,
        isIos,
        isAndroid,
        isWeb,
    }
}
