import { useAppState } from "@hooks/useState"
import React from "react"
import { useLocation, useNavigate } from "react-router"

export function useIntro() {
    const isHydrated = useAppState((state) => state.isHydrated)
    const hasSeenIntro = useAppState((state) => state.hasSeenIntro)
    const navigate = useNavigate()
    const location = useLocation()

    React.useEffect(() => {
        if (!isHydrated) {
            return
        }

        if (!hasSeenIntro && location.pathname !== "/auth/intro") {
            navigate("/auth/intro")
        }
    }, [navigate, location, hasSeenIntro, isHydrated])
}
