export * from "./loading-dots"
export * from "./link-button"
export * from "./button"

export type Variant = "primary" | "secondary" | "tertiary" | "warning" | "freeform"
export const Radius = {
    small: "rounded-lg",
    big: "rounded-3xl",
    full: "rounded-full",
}
export const variants: Record<Variant, string> = {
    primary:
        "flex justify-center gap-2 place-items-center px-4 min-h-10 font-button text-label bg-primary text-textStrong border-y gradient-border-light",
    secondary:
        "flex justify-center gap-2 place-items-center px-4 min-h-10 font-button text-label bg-primary/10 border-primary text-primary border",
    tertiary: "flex justify-center gap-2 place-items-center font-button px-4 min-h-10 text-label text-primaryAccent2",
    warning:
        "justify-center flex gap-2 place-items-center px-4 min-h-10 font-button text-label bg-solidTransparentWarning border-warning text-warning border",
    freeform: "",
}
