import { useDataPaths } from "@api/data-paths/useDataPaths"
import { Button } from "@components/atoms/buttons"
import { ContentOffset } from "@components/molecules/content-offset"
import Dialog from "@components/molecules/dialog"
import { ItemList } from "@components/organisms/item-list"
import { useOverlay } from "@hooks/useOverlay"
import { useSafeArea } from "@hooks/useSafeArea"
import { useAppState } from "@hooks/useState"
import { useTitlebar } from "@hooks/useTitlebar"
import { useLidarPlugin, useBuilding } from "@libs/lidar-lib"
import { isMobile } from "@utils"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router"
import { toast } from "sonner"

const pathMap = {
    street: "GDaten.Gebaeudeadresse_Strasse_Nr",
    zipcode: "GDaten.Gebaeudeadresse_Postleitzahl",
    city: "GDaten.Gebaeudeadresse_Ort",
}
const projectPaths = [pathMap.street, pathMap.zipcode, pathMap.city]

function RoomManagement() {
    const { id } = useParams()
    const projectId = Number(id)

    const appBarSize = useAppState((state) => state.appbarSize)
    const { open, close } = useOverlay()
    const { scan, edit } = useLidarPlugin()
    const { refreshFromStorage, addRoom, removeRoom, buildingMap, createBuilding, setWorldId, updateRoom } =
        useBuilding()
    const { data: projectPathData } = useDataPaths("projectPaths", projectId, projectPaths)
    const isLoaded = useRef(false)
    const [isMobileDevice, setMobileDevice] = useState<boolean | undefined>(undefined)
    const inset = useSafeArea()

    const navigate = useNavigate()
    useEffect(() => {
        refreshFromStorage().then(() => (isLoaded.current = true))
        isMobile().then((v) => setMobileDevice(v))
    }, [])

    useTitlebar({
        title: "Room Management",
        mode: "back",
        backTo: `/building-management/${id}`,
        showContextMenu: true,
        contextMenuProps: {
            showProjectOverview: true,
            showProjectSettings: true,
        },
        projectId,
    })

    const currentBuilding = useMemo(() => buildingMap.get(projectId), [buildingMap])

    useMemo(async () => {
        const currentBuilding = buildingMap.get(projectId)
        if ((currentBuilding === undefined || currentBuilding.buildingName == "") && isLoaded.current) {
            const buildingName = await open(
                <Dialog
                    onClose={close}
                    title="Gebäude Name"
                    buttonText="Speichern"
                    inputArgs={{
                        value: projectPathData[pathMap.street] ?? "",
                        placeholder: "Name",
                    }}
                />
            )
            if (typeof buildingName === "string" && buildingName.length > 0) {
                await createBuilding(buildingName, projectId)
            } else {
                toast.error("Der Gebäudename darf nicht leer sein")
                navigate(-1)
            }
        }
    }, [currentBuilding, projectPathData, isLoaded.current])

    const tryScan = useCallback(async () => {
        const maybeTitle = await open(
            <Dialog
                onClose={close}
                title="Bitte gebe den Raumnamen ein"
                buttonText="Okay"
                inputArgs={{ value: "", placeholder: "Raum Name" }}
            />
        )
        if (typeof maybeTitle === "string" && maybeTitle.length > 0) {
            const data = await scan(maybeTitle)
            if (data) {
                const { rooms, worldMapId } = data
                await setWorldId(projectId, worldMapId)
                for (const room of rooms) {
                    await addRoom(projectId, room)
                }
            }
        }
    }, [projectId, open, close, scan, addRoom])

    const tryEdit = useCallback(
        async (roomId: string) => {
            const maybeRoom = currentBuilding?.world?.rooms.find((r) => r.identifier === roomId)
            if (!maybeRoom) {
                toast.error("Der Raum konnte nicht gefunden werden")
                return
            }
            const editedCapturedRoom = await edit(maybeRoom)
            if (editedCapturedRoom) {
                await updateRoom(projectId, editedCapturedRoom)
            }
        },
        [projectId, currentBuilding, edit, removeRoom, addRoom]
    )
    const tryDelete = useCallback(
        async (roomId: string) => {
            const maybeRoom = currentBuilding?.world?.rooms.find((r) => r.identifier === roomId)
            if (!maybeRoom) {
                toast.error("Der Raum konnte nicht gefunden werden")
                return
            }
            await removeRoom(projectId, roomId)
        },
        [projectId, currentBuilding, removeRoom]
    )

    //TODO: Add some sample list items in here to check if the click event is being fired correctly
    const listItems = useMemo(
        () =>
            currentBuilding?.world?.rooms.map((room) => ({
                value: room.title,
                title: "Room",
                id: room.identifier,
            })) ?? [],
        [currentBuilding]
    )
    const EditIcon = () => <i className="ri-edit-box-line text-body1"></i>
    if (isMobileDevice === undefined) return null
    return (
        <>
            <div className="absolute -z-10 flex h-full w-full items-center justify-center text-[80vw] opacity-10 md:text-[40vw] lg:opacity-5">
                <i className="ri-home-gear-line" style={{}}></i>
            </div>
            <ContentOffset
                safeAreas={true}
                offsetAppbar={isMobileDevice ? false : true}
                className="grid h-dvh grid-rows-[minmax(0,1fr)_auto] overflow-hidden"
            >
                <div
                    className="mx-auto flex h-full w-full max-w-screen-lg flex-col justify-between gap-12 p-6"
                    style={{ paddingTop: `${isMobileDevice ? appBarSize[1] - inset.top : 0}px` }}
                >
                    <div>
                        <ItemList
                            onEdit={tryEdit}
                            onDelete={tryDelete}
                            title={currentBuilding?.buildingName ?? ""}
                            subTitle="Gebäude"
                            items={listItems}
                            icon={<EditIcon />}
                        />
                    </div>

                    <Button variant="primary" className="w-full" onClick={tryScan} onTouchEnd={tryScan}>
                        Scan
                    </Button>
                </div>
            </ContentOffset>
        </>
    )
}
export { RoomManagement }
