import { useDevicePlatform } from "@hooks/useDevicePlatform"
import { CapacitorPluginLidar, CapturedRoom } from "capacitor-lidar"
import { LidarPluginError, LidarPluginErrorType, handleError } from "./error"

const useLidarPlugin = () => {
    const { isWeb } = useDevicePlatform()

    const scan = async (title: string): Promise<{ rooms: CapturedRoom[]; worldMapId: string } | null> => {
        if (isWeb()) {
            handleError(new LidarPluginError("Unsupported Platform", LidarPluginErrorType.UnsupportedPlatform))
            return null
        }

        try {
            const data = await CapacitorPluginLidar.scan({ roomTitle: title })
            if (typeof data === "string") {
                if (data !== "Declined") {
                    handleError(new LidarPluginError(data, LidarPluginErrorType.PluginError))
                }
                return null
            }

            const { rooms, worldMapId } = data
            try {
                const capturedRooms = JSON.parse(rooms) as CapturedRoom[]
                return { rooms: capturedRooms, worldMapId }
            } catch (parseError) {
                handleError(new LidarPluginError("Failed to parse rooms JSON", LidarPluginErrorType.ParseError))
                return null
            }
        } catch (e) {
            handleError(new LidarPluginError("Unknown Error", LidarPluginErrorType.PluginError))
            return null
        }
    }

    const edit = async (room: CapturedRoom): Promise<CapturedRoom | null> => {
        const result = await CapacitorPluginLidar.editRoom({
            roomData: JSON.stringify(room),
        })
        if (typeof result === "string") {
            if (result !== "Declined") {
                handleError(new LidarPluginError(result, LidarPluginErrorType.PluginError))
            }
            return null
        } else {
            const room = JSON.parse(result.room) as CapturedRoom
            return room
        }
    }

    const isSupported = async (): Promise<boolean> => {
        const { supported } = await CapacitorPluginLidar.isSupported()
        return supported
    }

    return { scan, edit, isSupported }
}

export { useLidarPlugin, LidarPluginErrorType }
