import { useDataPaths } from "@api/data-paths/useDataPaths.ts"
import { useMutateDataPaths } from "@api/data-paths/useMutateDataPaths.ts"
import { useMutateProject } from "@api/projects/useMutateProject.ts"
import { useMutateProjectCover } from "@api/projects/useMutateProjectCover.ts"
import { useProject } from "@api/projects/useProject.ts"
import { useRemoveCover } from "@api/projects/useRemoveCover.ts"
import { useRemoveProject } from "@api/projects/useRemoveProject.ts"
import { LoadingDots } from "@components/atoms/buttons/loading-dots.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { Confirmation } from "@components/molecules/confirmation"
import { ContentOffset } from "@components/molecules/content-offset.tsx"
import { ChangeableImage, ChangeableText } from "@components/organisms/changeable-field.tsx"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { useNavigate, useParams } from "react-router"

const pathMap = {
    street: "GDaten.Gebaeudeadresse_Strasse_Nr",
    zipcode: "GDaten.Gebaeudeadresse_Postleitzahl",
    city: "GDaten.Gebaeudeadresse_Ort",
}

const projectPaths = [pathMap.street, pathMap.zipcode, pathMap.city]

export function ProjectSettings() {
    const { id } = useParams()
    const projectId = Number(id)

    const { data, isPending } = useProject(projectId)
    const { name = "---", cover } = data ?? {}

    const { data: projectPathData } = useDataPaths("projectPaths", projectId, projectPaths)

    const setActiveProject = useAppState((state) => state.setActiveProject)
    const navigate = useNavigate()

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${id}`,
        showContextMenu: true,
        contextMenuProps: {
            showProjectOverview: true,
            showProjectSettings: true,
        },
        projectId,
    })

    const projectMutation = useMutateProject(projectId)
    const projectCoverMutation = useMutateProjectCover(projectId)
    const removeCoverMutation = useRemoveCover(projectId)
    const removeProjectMutation = useRemoveProject(projectId)
    const projectPathsMutation = useMutateDataPaths("projectPaths", projectId)

    if (isPending) {
        return (
            <div className="grid h-full place-content-center">
                <LoadingDots />
            </div>
        )
    }

    return (
        <ContentOffset safeAreas={false} offsetAppbar={true}>
            <section className="mx-auto grid w-full max-w-screen-md content-start gap-6 p-6">
                <section className="grid gap-4">
                    <div className="grid gap-2">
                        <Heading level="h2">Allgemein</Heading>
                        <Text variant="body2" className=" text-textLight">
                            Hier kannst du die grundlegenden Daten des Gebäudes anpassen.
                        </Text>
                    </div>

                    <ChangeableImage
                        entryId={projectId}
                        value={cover?.url ?? ""}
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Titelbild",
                        }}
                        onMutate={async (data) => projectCoverMutation.mutateAsync({ data })}
                        onDelete={async () => removeCoverMutation.mutateAsync()}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Name",
                        }}
                        inputProps={{
                            name: "name",
                            value: name ?? "",
                        }}
                        onMutate={async (data) => projectMutation.mutateAsync({ data })}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Straße und Hausnummer",
                        }}
                        inputProps={{
                            name: pathMap.street,
                            value: projectPathData[pathMap.street] ?? "",
                        }}
                        onMutate={async (data) => projectPathsMutation.mutate(data)}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "PLZ",
                        }}
                        inputProps={{
                            name: pathMap.zipcode,
                            value: projectPathData[pathMap.zipcode] ?? "",
                        }}
                        onMutate={async (data) => projectPathsMutation.mutate(data)}
                    />
                    <ChangeableText
                        changeableFieldProps={{
                            mode: "edit",
                            label: "Ort",
                        }}
                        inputProps={{
                            name: pathMap.city,
                            value: projectPathData[pathMap.city] ?? "",
                        }}
                        onMutate={async (data) => projectPathsMutation.mutate(data)}
                    />
                </section>

                <section className="grid gap-4">
                    <div className="grid gap-2">
                        <Heading level="h2" className="text-text">
                            Projekt löschen
                        </Heading>
                        <Text variant="body2" className=" text-textLight">
                            Hier kannst du dein Projekt und alle damit verbundenen Daten löschen.
                        </Text>
                    </div>
                    <Confirmation
                        trigger="Projekt löschen"
                        title="Projekt löschen"
                        description="Willst du dieses Projekt wirklich unwiderruflich löschen?"
                        cancel="Lieber nicht"
                        action="Projekt löschen"
                        onConfirm={async () => {
                            await removeProjectMutation.mutateAsync()
                            setActiveProject(null)
                            navigate("/authenticated/chat")
                        }}
                    />
                </section>
            </section>
        </ContentOffset>
    )
}
