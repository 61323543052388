import { handleFetch, apiUrl } from "@api/apiConfig"

export type IVerifyApple = {
    access_code: string
}

export async function verifyApple(params: IVerifyApple) {
    return handleFetch(async () =>
        fetch(`${apiUrl}/apple-signin-provider/claim`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
    )
}
