import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useUserKey } from "@api/user/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type MutationParams = {
    id: number
    data: FormData
}

export function useMutateUser() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: MutationParams) => {
            if (!authHeader) {
                return { error: "No auth header provided" }
            }

            const { id, data } = params
            return handleFetch(() =>
                fetch(`${apiUrl}/users/${id}`, {
                    method: "put",
                    body: data,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gespeichert")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
