import { cls } from "@utils"
import { motion } from "framer-motion"
import { IEnerGuide } from "."

export function EnerGuideDark(props: IEnerGuide) {
    const { className, energuideClassName: energuideClassName } = props
    return (
        <div className={cls(className)}>
            <motion.div
                layoutId="energuide-dark"
                className={`rounded-full ${energuideClassName}`}
                style={{
                    backgroundImage: "radial-gradient(transparent 30%, black)",
                }}
            ></motion.div>
        </div>
    )
}
