import { ImageSchema } from "@api/image"
import { z } from "zod"

export const SenderSchema = z.object({
    displayName: z.string().nullish(),
    avatar: ImageSchema.nullish(),
})

export type ISender = z.infer<typeof SenderSchema>

export const ChatSchema = z.object({
    id: z.number(),
    role: z.string(),
    intent_type: z.string().nullish(),
    content: z.string().nullish(),
    sender: SenderSchema.nullish(),
    choices: z.array(z.string()).nullish(),
    files: z.array(ImageSchema),
    lastUpdate: z.coerce.date(),
})

export const ChatsSchema = z.array(ChatSchema)

export type IChat = z.infer<typeof ChatSchema>
