import { useState } from "react"
import { IBuilding, CapturedRoom, LidarPluginError, LidarPluginErrorType, handleError } from "."
import { BuildingStorage } from "./storage/buildingStorage"

const useBuilding = () => {
    const [buildingMap, setBuildingMap] = useState(new Map<number, IBuilding>())
    const storage = new BuildingStorage()

    const createBuilding = async (buildingName: string, id: number): Promise<IBuilding> => {
        const newBuilding = { buildingName, id: id, world: null }
        setBuildingMap((prevMap) => {
            const updatedMap = new Map(prevMap)
            updatedMap.set(id, newBuilding)
            return updatedMap
        })
        await storage.save(newBuilding)
        return newBuilding
    }

    const retrieveBuilding = (key: number): IBuilding | undefined => buildingMap.get(key)
    const isRoom = (buildingKey: number, roomKey: string) =>
        buildingMap.get(buildingKey)?.world?.rooms.find((room) => room.identifier === roomKey) !== undefined
    const renameBuilding = async (key: number, newName: string): Promise<boolean> => {
        return await updateAndSaveBuilding(key, (building) => ({ ...building, buildingName: newName }))
    }

    const setWorldId = async (key: number, worldId: string): Promise<boolean> => {
        return await updateAndSaveBuilding(key, (building) => ({ ...building, world: { id: worldId, rooms: [] } }))
    }
    const addRoom = async (key: number, newRoom: CapturedRoom) => {
        return await updateAndSaveBuilding(key, (building) => {
            if (building.world === null) {
                handleError(
                    new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                )
                return building
            } else {
                return {
                    ...building,
                    world: { id: building.world?.id, rooms: [...building.world!.rooms, newRoom] },
                }
            }
        })
    }
    const updateRoom = async (key: number, updatedRoom: CapturedRoom) => {
        return await updateAndSaveBuilding(key, (building) => {
            if (building.world === null) {
                handleError(
                    new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                )
                return building
            } else {
                return {
                    ...building,
                    world: {
                        id: building.world?.id,
                        rooms: building.world.rooms.map((room) =>
                            room.identifier === updatedRoom.identifier ? updatedRoom : room
                        ),
                    },
                }
            }
        })
    }
    const removeRoom = async (key: number, roomId: string) => {
        updateAndSaveBuilding(key, (building) => {
            if (building.world === null) {
                handleError(
                    new LidarPluginError("Building does not have a world", LidarPluginErrorType.InvalidOperation)
                )
                return building
            } else {
                return {
                    ...building,
                    world: {
                        id: building.world?.id,
                        rooms: building.world.rooms.filter((room) => room.identifier !== roomId),
                    },
                }
            }
        })
    }

    const deleteBuilding = async (key: number) => {
        setBuildingMap((prevMap) => {
            const updatedMap = new Map(prevMap)

            updatedMap.delete(key)
            return updatedMap
        })
        await storage.delete(String(key))
    }

    const refreshFromStorage = async (): Promise<IBuilding[] | null> => {
        const data = await storage.list()
        if (data) {
            setBuildingMap(new Map(data.map((building) => [building.id, building])))
        }
        return data
    }

    const updateAndSaveBuilding = async (
        key: number,
        updateFn: (building: IBuilding) => IBuilding
    ): Promise<boolean> => {
        let error: LidarPluginError | undefined
        let newBuilding: IBuilding | undefined

        const updatedMap = new Map(buildingMap)
        const existingBuilding = updatedMap.get(key)

        if (existingBuilding) {
            newBuilding = updateFn(existingBuilding)
            updatedMap.set(key, newBuilding)
        } else {
            error = new LidarPluginError("Building does not exist", LidarPluginErrorType.IdentifierDoesNotExist)
        }

        if (error) {
            handleError(error)
            return false
        } else {
            setBuildingMap(updatedMap) // Apply the new map
            if (newBuilding) {
                await storage.save(newBuilding)
            }
            return true
        }
    }

    return {
        buildingMap,
        createBuilding,
        retrieveBuilding,
        setWorldId,
        addRoom,
        removeRoom,
        deleteBuilding,
        refreshFromStorage,
        renameBuilding,
        updateRoom,
        isRoom,
    }
}
export { useBuilding }
