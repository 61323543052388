export function MoreIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 13C5.9 13 5 13.9 5 15C5 16.1 5.9 17 7 17C8.1 17 9 16.1 9 15C9 13.9 8.1 13 7 13ZM17 13C15.9 13 15 13.9 15 15C15 16.1 15.9 17 17 17C18.1 17 19 16.1 19 15C19 13.9 18.1 13 17 13ZM12 5C10.9 5 10 5.9 10 7C10 8.1 10.9 9 12 9C13.1 9 14 8.1 14 7C14 5.9 13.1 5 12 5Z"
                fill="currentColor"
            />
        </svg>
    )
}
