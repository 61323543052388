import React from "react"

export function useMediaQuery(query: string) {
    const [matches, setMatches] = React.useState<boolean>(false)

    const handleMatch = (event: MediaQueryListEvent) => {
        setMatches(event.matches)
    }

    React.useLayoutEffect(() => {
        const result = window.matchMedia(query)
        setMatches(result.matches)

        result.addEventListener("change", handleMatch)

        return () => {
            result.removeEventListener("change", handleMatch)
        }
    }, [query])

    return matches
}
