import { Storage } from "@ionic/storage"
import { StorageError, StorageErrorType } from "."
import { handleError, IBuilding } from ".."

class BuildingStorage {
    private storage: Storage
    private isInitialized: Promise<void>

    constructor() {
        this.storage = new Storage({
            name: "buildingDb",
            storeName: "building",
            dbKey: "db-encryption-key-$9683D",
        })
        this.isInitialized = this.initialize()
    }

    private async initialize(): Promise<void> {
        await this.storage.create()
    }

    private async ensureInitialized(): Promise<void> {
        await this.isInitialized
    }

    async save(building: IBuilding): Promise<void> {
        console.log("saving building")
        await this.ensureInitialized()
        console.log("building: ", building)
        const buildingData = JSON.stringify(building)
        console.log("setting building for id: ", building.id)
        await this.storage.set(String(building.id), buildingData)
    }

    async retrieve(identifier: string): Promise<IBuilding | null> {
        await this.ensureInitialized()
        try {
            const buildingData = await this.storage.get(identifier)
            if (!buildingData) {
                handleError(new StorageError("Building does not exist", StorageErrorType.IdentifierDoesNotExist))
                return null
            }
            return JSON.parse(buildingData) as IBuilding
        } catch (error) {
            handleError(new StorageError("Failed to retrieve building", StorageErrorType.ParseError))
            return null
        }
    }

    async delete(identifier: string): Promise<boolean> {
        await this.ensureInitialized()
        try {
            const keys = await this.storage.keys()
            if (keys.findIndex((key) => key === identifier) === -1) {
                handleError(new StorageError("Building does not exist", StorageErrorType.IdentifierDoesNotExist))
            }
            await this.storage.remove(identifier)
            return true
        } catch (error) {
            console.error("error")
            handleError(new StorageError("Failed to delete building", StorageErrorType.InternalStorageError))
            return false
        }
    }

    async list(): Promise<IBuilding[] | null> {
        await this.ensureInitialized()
        const keys = await this.storage.keys()
        const data: IBuilding[] = []

        for (const key of keys) {
            const building = await this.retrieve(key)
            if (building) {
                data.push(building)
            } else {
                return null
            }
        }
        return data
    }

    async purge(): Promise<void> {
        await this.ensureInitialized()
        try {
            await this.storage.clear()
        } catch (error) {
            handleError(new StorageError("Failed to purge rooms", StorageErrorType.InternalStorageError))
        }
    }
}

export { BuildingStorage }
