import React from "react"

export function useSize<T extends HTMLElement>() {
    const ref = React.useRef<T | null>(null)
    const [size, setSize] = React.useState<number[]>([0, 0])

    React.useLayoutEffect(() => {
        if (!ref.current) {
            return
        }

        const observer = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { blockSize, inlineSize } = entry.borderBoxSize[0]
                setSize([inlineSize, blockSize])
            }
        })

        observer.observe(ref.current)

        setSize([ref.current.offsetWidth, ref.current.offsetHeight])

        return () => {
            observer.disconnect()
        }
    }, [])

    return {
        ref,
        size,
    }
}
