import { handleFetch, apiUrl, baseUrl } from "@api/apiConfig"
import { UserSchema } from "@api/user/schema"
import { useAppState } from "@hooks/useState"

export type ILogin = {
    identifier: string
    password: string
}

export async function login(params: ILogin) {
    const { user, jwt } = await handleFetch(async () =>
        fetch(`${apiUrl}/auth/local`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
        })
    )

    const parsedUser = UserSchema.parse({
        ...user,
        avatar: {
            ...user.avatar,
            url: `${baseUrl}${user.avatar?.url}`,
        },
    })

    useAppState.getState().setAuthentication(jwt, parsedUser)

    return parsedUser
}
