import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import * as Form from "@radix-ui/react-form"
import { cls } from "@utils"
import React from "react"

const getTextNodes = (rootNode: Node) => {
    const treeWalker = document.createTreeWalker(rootNode, NodeFilter.SHOW_TEXT)
    const textNodes = []
    let currentNode = treeWalker.nextNode()
    while (currentNode) {
        textNodes.push(currentNode)
        currentNode = treeWalker.nextNode()
    }

    return textNodes
}

const highlightText = (element: HTMLElement, term: string) => {
    // stop if the CSS Custom Highlight API is not supported
    if (!CSS.highlights) {
        return
    }

    CSS.highlights.clear()

    if (!element) {
        return
    }

    const str = term.trim().toLowerCase()
    if (!str) {
        return
    }

    // Iterate over all text nodes and find matches.
    const ranges = getTextNodes(element)
        .map((el) => {
            return {
                el,
                text: el?.textContent?.toLowerCase() ?? "",
            }
        })
        .map(({ text, el }) => {
            const indices = []
            let startPos = 0
            while (startPos < text.length) {
                const index = text.indexOf(str, startPos)
                if (index === -1) {
                    break
                }
                indices.push(index)
                startPos = index + str.length
            }

            // Create a range object for each instance of
            // str we found in the text node.
            return indices.map((index) => {
                const range = new Range()
                range.setStart(el, index)
                range.setEnd(el, index + str.length)
                return range
            })
        })

    // Create a Highlight object for the ranges.
    const searchResultsHighlight = new Highlight(...ranges.flat())

    // Register the Highlight object in the registry.
    CSS.highlights.set("search-results", searchResultsHighlight)
}

type SearchInputProps = {
    className?: string
    onSearch: (term: string) => HTMLElement[]
    onClose: () => void
}

export function SearchInput(props: SearchInputProps) {
    const { className, onSearch, onClose } = props
    const [elements, setElements] = React.useState<HTMLElement[]>([])
    const [current, setCurrent] = React.useState<number>(0)
    const [term, setTerm] = React.useState<string>("")

    const onInput: React.FormEventHandler<HTMLInputElement> = (event) => {
        const input = event.target as HTMLInputElement
        setTerm(input.value)
        setElements(onSearch(input.value))
    }

    React.useEffect(() => {
        if (elements.length === 0) {
            return
        }

        setCurrent(0)
    }, [elements])

    React.useEffect(() => {
        const element = elements?.[current]
        highlightText(element, term)
        if (!element) {
            return
        }

        element.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        })

        return () => {
            CSS.highlights.clear()
        }
    }, [current, elements, term])

    const next = () => {
        if (elements.length === 0) {
            return
        }

        const nextIdx = current + 1
        if (nextIdx >= elements.length) {
            setCurrent(0)
        } else {
            setCurrent(nextIdx)
        }
    }

    const prev = () => {
        if (elements.length === 0) {
            return
        }

        const prevIdx = current - 1
        if (prevIdx < 0) {
            setCurrent(elements.length - 1)
        } else {
            setCurrent(prevIdx)
        }
    }

    const onEnter: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault()
        next()
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (event) => {
        if (event.key === "Escape") {
            onClose()
        }
    }

    return (
        <Form.Root
            className={cls("grid justify-items-center px-6 *:w-full *:sm:w-[50ch]", className)}
            onSubmit={onEnter}
            onKeyDown={onKeyDown}
        >
            <GradientBorder variant="dark" radius="full">
                <Form.Field
                    name="search"
                    className="grid grid-cols-[auto,1fr,auto] gap-4 rounded-full bg-background py-2 pl-2 pr-4 text-primaryAccent2 shadow-xl outline
                        outline-1 outline-secondary/50"
                >
                    <div className="grid aspect-square place-items-center rounded-full bg-secondary text-backgroundStrong">
                        <i className="ri-chat-search-line text-[1.5rem]"></i>
                    </div>
                    <Form.Control asChild className="min-w-0" autoFocus>
                        <input autoCapitalize="off" autoCorrect="off" onInput={onInput} className="bg-transparent" />
                    </Form.Control>
                    <div className="flex items-center gap-1">
                        {elements.length !== 0 && (
                            <>
                                <span className="whitespace-nowrap text-tiny tabular-nums">
                                    {current + 1} / {elements.length}
                                </span>
                                <Button variant="freeform" type="button" onClick={prev}>
                                    <i className="ri-arrow-drop-up-line text-[1.5rem]"></i>
                                </Button>
                                <Button variant="freeform" type="button" onClick={next}>
                                    <i className="ri-arrow-drop-down-line text-[1.5rem]"></i>
                                </Button>
                            </>
                        )}
                        <Button variant="freeform" type="button" onClick={onClose}>
                            <i className="ri-close-line text-[1.5rem]"></i>
                        </Button>
                    </div>
                </Form.Field>
            </GradientBorder>
        </Form.Root>
    )
}
