import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ProjectSchema } from "@api/projects/schema"
import { transform } from "@api/projects/useProjects"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

type IUseProject = {
    enabled?: boolean
}

export function useProject(id: number | null, params?: IUseProject) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["project", id],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/projects/${id}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const project = handleParse(() => ProjectSchema.parse(transform(data)))
            return project
        },
        enabled: !!enabled && !!authHeader && !!id,
    })
}
