import { verifyApple } from "@api/auth/verifyApple"
import { verifyFacebook } from "@api/auth/verifyFacebook"
import { verifyGoogle } from "@api/auth/verifyGoogle"
import { LoadingDots } from "@components/atoms/buttons"
import { useAppState } from "@hooks/useState"
import React from "react"
import { useNavigate } from "react-router"
import { toast } from "sonner"

export function GoogleRedirect() {
    const setAuthentication = useAppState((state) => state.setAuthentication)
    const navigate = useNavigate()

    React.useEffect(() => {
        const func = async () => {
            try {
                const queryParameters = new URLSearchParams(window.location.search)
                const access_token = queryParameters.get("access_token")
                if (!access_token) {
                    return
                }

                const { jwt, user } = await verifyGoogle({ access_token })

                setAuthentication(jwt, user)
                navigate("/authenticated/chat")
            } catch (error: any) {
                toast.error(error.message)
                navigate("/auth/login-selection")
            }
        }

        void func()
    }, [navigate, setAuthentication])

    return (
        <div className="grid h-dvh w-full place-content-center">
            <LoadingDots />
        </div>
    )
}

export function FacebookRedirect() {
    const setAuthentication = useAppState((state) => state.setAuthentication)
    const navigate = useNavigate()

    React.useEffect(() => {
        const func = async () => {
            try {
                const queryParameters = new URLSearchParams(window.location.search)
                const access_token = queryParameters.get("access_token")
                if (!access_token) {
                    return
                }

                const { jwt, user } = await verifyFacebook({ access_token })

                setAuthentication(jwt, user)
                navigate("/authenticated/chat")
            } catch (error: any) {
                toast.error(error.message)
                navigate("/auth/login-selection")
            }
        }

        void func()
    }, [navigate, setAuthentication])

    return (
        <div className="grid h-dvh w-full place-content-center">
            <LoadingDots />
        </div>
    )
}

export function AppleRedirect() {
    const setAuthentication = useAppState((state) => state.setAuthentication)
    const navigate = useNavigate()

    React.useEffect(() => {
        const func = async () => {
            try {
                const queryParameters = new URLSearchParams(window.location.search)
                const access_code = queryParameters.get("access_code")
                if (!access_code) {
                    return
                }

                const { id_token, user } = await verifyApple({ access_code })

                setAuthentication(id_token, user)
                navigate("/authenticated/chat")
            } catch (error: any) {
                toast.error(error.message)
                navigate("/auth/login-selection")
            }
        }

        void func()
    }, [navigate, setAuthentication])

    return (
        <div className="grid h-dvh w-full place-content-center">
            <LoadingDots />
        </div>
    )
}
