import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

export function useRemoveProject(projectId: number) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !projectId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/projects/${projectId}`, {
                    method: "delete",
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gelöscht")
            await queryClient.invalidateQueries({
                queryKey: ["projects"],
            })
        },
    })
}
