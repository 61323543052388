import { queryClient } from "@api/apiConfig.ts"
import OverlayProvider from "@components/molecules/overlay/overlay-context.tsx"
import * as Sentry from "@sentry/capacitor"
import * as SentryReact from "@sentry/react"
import { QueryClientProvider } from "@tanstack/react-query"
import React from "react"
import ReactDOM from "react-dom/client"
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import "remixicon/fonts/remixicon.css"
import { App } from "./App.tsx"
import "./index.css"

Sentry.init(
    {
        dsn: "https://f021ad9fa333b620c21a73d340bb151d@sentry.enerithm.com/2",
        integrations: [
            new SentryReact.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/api.enerithm\.com\/api/],
            }),
            new SentryReact.Replay({
                maskAllText: false,
                blockAllMedia: false,
                maxReplayDuration: 60000,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    },
    // Forward the init method from @sentry/react
    SentryReact.init
)

const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />))

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <OverlayProvider>
                <RouterProvider router={router} />
            </OverlayProvider>
        </QueryClientProvider>
    </React.StrictMode>
)
