import { IProject } from "@api/projects/schema.ts"
import { Button } from "@components/atoms/buttons"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { DataModal } from "@components/organisms/data-modal.tsx"
import * as Form from "@radix-ui/react-form"
import React from "react"
import { Link } from "react-router-dom"
import { toast } from "sonner"

type IProjectList = {
    projects: IProject[]
    onAdd: (data: FormData) => Promise<void>
}

export function ProjectList(props: IProjectList) {
    const { projects, onAdd } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        try {
            setLoading(true)
            await onAdd(new FormData(event.currentTarget))
            setOpen(false)
        } catch (error: any) {
            console.error(error)
            if (error.message) {
                toast.error(error?.message)
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <nav className="grid h-full grid-rows-[auto_minmax(0,1fr)] gap-4 overflow-hidden px-6 py-8">
            <Heading level="h3">Projekte</Heading>
            <div className="grid content-start gap-4 overflow-auto">
                {projects.map((project) => (
                    <Link to={`/authenticated/chat/${project.id}`} className="flex items-center gap-4" key={project.id}>
                        <i className="ri-home-fill text-[1.5rem] leading-none text-text"></i>
                        <Text variant="strong2" className="text-text">
                            {project.name}
                        </Text>
                    </Link>
                )) ?? (
                    <Text variant="strong2" className="text-textVeryLight">
                        Kein Projekte
                    </Text>
                )}

                <DataModal
                    iconClass="ri-add-line"
                    title="Projekt erstellen"
                    description="Wie soll das Projekt heißen?"
                    open={open}
                    onOpenChange={setOpen}
                    trigger={
                        <Button variant="freeform" className="flex items-center gap-4">
                            <i
                                className={
                                    "ri-add-line grid place-items-center text-[1.5rem] leading-none text-textVeryLight"
                                }
                            ></i>
                            <Text variant="strong2" className=" text-textVeryLight">
                                Hinzufügen
                            </Text>
                        </Button>
                    }
                >
                    <Form.Root className="grid gap-6" onSubmit={onSubmit}>
                        <Input
                            name="name"
                            type="text"
                            label="Name"
                            required
                            messages={[
                                {
                                    content: "Projektname benötigt",
                                    match: "valueMissing",
                                },
                            ]}
                        />
                        <Button variant="primary" loading={loading}>
                            Erstellen
                        </Button>
                    </Form.Root>
                </DataModal>
            </div>
        </nav>
    )
}
