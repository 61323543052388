import { useCertificate } from "@api/certificate/useCertificate"
import { useDataPaths } from "@api/data-paths/useDataPaths"
import { useKpis } from "@api/kpis/useKpis"
import { useProject } from "@api/projects/useProject.ts"
import { Button, LinkButton } from "@components/atoms/buttons"
import { GradientBackground } from "@components/atoms/gradient"
import { Image } from "@components/atoms/image.tsx"
import { ProgressBar } from "@components/atoms/progress.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { BigStat } from "@components/molecules/big-stat.tsx"
import { CertificateButton } from "@components/molecules/certificate-button.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { useParams } from "react-router"

const pathMap = {
    gebaeudeFoto: "GDaten.Gebaeude_Foto",
}

export function ProjectOverview() {
    const { id } = useParams()
    const { data } = useProject(Number(id))
    const { data: paths } = useDataPaths("GDaten.Gebaeude_Foto", Number(id), Object.values(pathMap))
    const { name = "---", cover } = data ?? {}

    const { data: certificate } = useCertificate(Number(id), "demand")
    const { data: kpis } = useKpis(Number(id), {
        // only run the KPIs query if a certificate was already created
        enabled: !!certificate?.certificate && !certificate?.error,
    })

    useTitlebar({
        title: name ?? "",
        mode: "back",
        backTo: `/authenticated/chat/${id}`,
        showContextMenu: true,
        contextMenuProps: {
            showProjectOverview: true,
            showProjectSettings: true,
        },
        projectId: id ? Number(id) : null,
    })

    return (
        <section className="mx-auto grid max-w-screen-md content-start gap-10 pb-4">
            <div className="relative aspect-square object-cover lg:aspect-video">
                <Image
                    src={cover?.url ?? paths[pathMap.gebaeudeFoto] ?? ""}
                    alt=""
                    fallback={<i className="ri-home-3-line text-[3rem] text-textLight"></i>}
                    className="h-full w-full"
                />
                <Heading level="h1" className="absolute bottom-3 left-4">
                    {name}
                </Heading>
            </div>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Gebäudepass
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Der digitale Gebäudepass bildet die Grundlage deiner individuellen Energieberatung mit Energuide.
                </Text>
            </section>

            <section className="relative grid grid-cols-2 gap-4 px-2">
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Endenergie"
                    value={
                        kpis?.endenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                />
                <BigStat
                    iconClass="ri-fire-fill"
                    name="Primärenergie"
                    value={
                        kpis?.primaerenergie?.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }) ?? "-"
                    }
                    unit="kWh/(m²a)"
                />
                <BigStat
                    iconClass="ri-cloud-fill"
                    name="CO₂"
                    value={
                        kpis?.co2?.toLocaleString("de-DE", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                        }) ?? "-"
                    }
                    unit="kg/(m²a)"
                />
                <BigStat
                    iconClass="ri-verified-badge-fill"
                    name="Effizienzklasse"
                    value={kpis?.effizienzklasse ?? "-"}
                    unit="nach GEG 24"
                />
            </section>

            <section className="px-2">
                <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                    <div className="relative grid gap-1">
                        <Heading level="h4" className="text-text">
                            Zustandsreport
                        </Heading>
                        <Text variant="body2" className=" text-textVeryLight">
                            Fortschritt
                        </Text>

                        <LinkButton
                            className="absolute right-0 top-0 aspect-square border p-1"
                            variant="secondary"
                            radius="full"
                            href={`/authenticated/data-overview/${id}`}
                        >
                            <i className="ri-list-view text-[1.5rem] leading-none"></i>
                        </LinkButton>
                    </div>
                    <ProgressBar value={14} max={36} />
                    <LinkButton variant="secondary" href={`/authenticated/chat/${id}`}>
                        Weitermachen
                    </LinkButton>
                </GradientBackground>
            </section>

            <section className="grid gap-2 px-6">
                <Heading level="h2" className=" text-text">
                    Services
                </Heading>
                <Text variant="body2" className="text-textLight">
                    Wähle einen der folgenden Services für dein Gebäude und lass dich von Energuide beraten.
                </Text>
            </section>

            <section className="px-2">
                <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                    <div className="relative grid gap-1">
                        <Heading level="h4" className="text-text">
                            Verbrauchsausweis
                        </Heading>
                        <Text variant="body2" className=" text-textVeryLight">
                            18/18 Fragen beantwortet
                        </Text>

                        <Button
                            variant="secondary"
                            className="absolute right-0 top-0 aspect-square border p-1"
                            radius="full"
                        >
                            <i className="ri-list-view text-[1.5rem] leading-none"></i>
                        </Button>
                    </div>
                    <ProgressBar value={18} max={18} />

                    <CertificateButton projectId={Number(id)} type="consumption" />
                </GradientBackground>
            </section>

            <section className="px-2">
                <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
                    <div className="relative grid gap-1">
                        <Heading level="h4" className="text-text">
                            Bedarfsausweis
                        </Heading>
                        <Text variant="body2" className=" text-textVeryLight">
                            8/27 Fragen beantwortet
                        </Text>

                        <Button
                            variant="secondary"
                            className="absolute right-0 top-0 aspect-square border p-1"
                            radius="full"
                        >
                            <i className="ri-list-view text-[1.5rem] leading-none"></i>
                        </Button>
                    </div>
                    <ProgressBar value={8} max={27} />

                    <CertificateButton projectId={Number(id)} type="demand" />
                </GradientBackground>
            </section>
        </section>
    )
}
