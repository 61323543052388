import { apiUrl, baseUrl, handleFetch, handleParse } from "@api/apiConfig"
import { ProjectsSchema } from "@api/projects/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

export function transform(project: any) {
    const cover = project.attributes.cover?.data
    const hasCover = !!cover?.attributes?.url

    return {
        id: project.id,
        ...project.attributes,
        cover: {
            ...cover?.attributes,
            url: hasCover ? `${baseUrl}${cover?.attributes?.url}` : null,
        },
        authorizations: project.attributes.project_authorizations.data.map((authorization: any) => {
            const projectRole = authorization.attributes.project_role.data
            const user = authorization.attributes.users_permissions_user.data
            const avatar = user?.avatar?.data
            const hasAvatar = avatar?.attributes?.url

            return {
                user: {
                    displayName: user?.attributes.displayName,
                    email: user?.attributes.email,
                    avatar: hasAvatar ? `${baseUrl}${avatar?.attributes.url}` : null,
                },
                role: {
                    role: projectRole?.attributes.role,
                    id: projectRole?.id,
                },
            }
        }),
        createdAt: project.attributes.createdAt,
        updatedAt: project.attributes.updatedAt,
    }
}

type IUseProjects = {
    enabled?: boolean
}

export function useProjects(params?: IUseProjects) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        placeholderData: [],
        queryKey: ["projects"],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/projects`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const transformed = data.map(transform)
            const projects = handleParse(() => ProjectsSchema.parse(transformed))

            // sort by creation date
            projects.sort((a, b) => {
                return a.createdAt.localeCompare(b.createdAt)
            })

            return projects
        },
        enabled: !!enabled && !!authHeader,
    })
}
