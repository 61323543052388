import { useProjects } from "@api/projects/useProjects"
import { useAppState } from "@hooks/useState"
import React from "react"
import { useNavigate } from "react-router"

export function InitialProjectLoader() {
    const navigate = useNavigate()
    const token = useAppState((state) => state.token)
    const { data: projects, isPending, error } = useProjects()

    React.useEffect(() => {
        if (!token || isPending) {
            return
        }

        if (projects && projects.length) {
            navigate(`/authenticated/chat/${projects[0].id}`)
        }
    }, [navigate, isPending, projects, token])

    if (error) {
        console.error(error)
    }

    return null
}
