import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { IFaqHint, FaqHintsSchema } from "@api/faq-hints/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

function transform(faqHint: any): IFaqHint {
    return {
        id: faqHint.id,
        question: faqHint.attributes.question,
    }
}

type IUseFaqHints = {
    enabled?: boolean
}

export function useFaqHints(params?: IUseFaqHints) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        placeholderData: [],
        queryKey: ["faq-hints"],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/faq-hints`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const faqHints = handleParse(() => FaqHintsSchema.parse(data.map(transform)))
            return faqHints
        },
        enabled: !!enabled && !!authHeader,
    })
}
