import { Button } from "@components/atoms/buttons"
import { useAppState } from "@hooks/useState.ts"
import { useNavigate } from "react-router"

export function Logout() {
    const clearAuth = useAppState((state) => state.clearAuth)
    const navigate = useNavigate()

    const logout = () => {
        // we scroll to top to avoid animation issues
        window.scrollTo({
            top: 0,
        })
        clearAuth()
        navigate("/auth/login-selection")
    }

    return (
        <Button variant="secondary" onClick={logout}>
            Ausloggen
        </Button>
    )
}
