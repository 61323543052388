import { apiUrl, baseUrl, handleFetch, handleParse } from "@api/apiConfig"
import { CertificateSchema, ICertificateType } from "@api/certificate/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"

function transform(certificate: any) {
    const file = certificate.attributes.certificate.data?.attributes
    return {
        id: certificate.id,
        taskId: certificate.attributes.taskId,
        certificate: file
            ? {
                  ...file,
                  url: `${baseUrl}${file.url}`,
              }
            : null,
        type: certificate.attributes.type,
        error: certificate.attributes.error,
    }
}

type IUseCertificate = {
    enabled?: boolean
}

export function useCertificate(projectId: number, type: ICertificateType, params?: IUseCertificate) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["certificate", projectId, type],
        queryFn: async () => {
            const response = await handleFetch(async () => {
                const response = await fetch(`${apiUrl}/energy-certificate/for?projectId=${projectId}&type=${type}`, {
                    headers: {
                        ...authHeader,
                    },
                })

                // don't show error when no entry found
                if (response.status === 404) {
                    return null
                }

                return response
            })

            if (!response) {
                return null
            }

            const { data } = response
            const certificate = handleParse(() => CertificateSchema.parse(transform(data)))
            return certificate
        },
        refetchInterval: (query) => {
            const { data } = query.state
            return data && !data.certificate && !data.error ? 5000 : false
        },
        enabled: !!enabled && !!authHeader,
    })
}
