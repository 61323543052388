import { LinkButton } from "@components/atoms/buttons"
import { EnerGuide } from "@components/atoms/energuide"
import { Heading, Text } from "@components/atoms/typography"
import { useAppState } from "@hooks/useState"
import { useTitlebar } from "@hooks/useTitlebar"
import { cls } from "@utils"
import useEmblaCarousel from "embla-carousel-react"
import React from "react"
import { Link } from "react-router-dom"

type ISlide = {
    heading: string
    content: string
    selected: number
    total: number
}

export function Slide(props: ISlide) {
    const { heading, content, selected, total } = props
    return (
        <div className="grid min-w-0 shrink-0 grow-0 basis-full content-end gap-4 px-8">
            <div className="mx-auto flex w-full max-w-screen-md justify-between">
                <Text variant="strong1" className=" text-textLight">
                    Intro
                </Text>
                <Text variant="strong1" className=" text-textLight">
                    {selected} / {total}
                </Text>
            </div>
            <div className="grid min-h-[30dvh] content-start gap-4">
                <Heading level="h2" className=" text-textStrong">
                    {heading}
                </Heading>
                <Text variant="body1" className=" text-textLight">
                    {content}
                </Text>
            </div>
        </div>
    )
}

export function Intro() {
    const [ref, api] = useEmblaCarousel()
    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([])
    const [selectedIndex, setSelectedIndex] = React.useState(0)
    const onIntroDone = useAppState((state) => state.setHasSeenIntro)

    useTitlebar({
        title: "",
        mode: "hide",
        showContextMenu: false,
    })

    const arrowClickCb = (event: KeyboardEvent) => {
        if (api) {
            if (event.key === "ArrowRight") {
                api.scrollNext()
            } else if (event.key === "ArrowLeft") {
                api.scrollPrev()
            }
        }
    }

    React.useEffect(() => {
        return () => {
            document.removeEventListener("keydown", arrowClickCb)
        }
    }, [])

    React.useEffect(() => {
        if (!api) {
            return
        }

        document.addEventListener("keydown", arrowClickCb)

        const onInit = () => {
            if (!api) {
                return
            }

            setScrollSnaps(api.scrollSnapList())
        }

        const onSelect = () => {
            if (!api) {
                return
            }

            setSelectedIndex(api.selectedScrollSnap())
        }

        onInit()
        onSelect()
        api.on("reInit", onInit)
        api.on("reInit", onSelect)
        api.on("select", onSelect)
    }, [api])

    return (
        <div className="grid min-h-dvh grid-rows-[1fr_auto] justify-items-end gap-4">
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-20%] z-[-1] aspect-square w-[90%] sm:w-[70%] md:w-[50%] lg:w-[50%] xl:w-[30%] justify-self-center"
                layoutId="energuide"
            />

            <div ref={ref} className="mx-auto w-full max-w-screen-md overflow-hidden">
                <div className="flex h-full gap-4">
                    <Slide
                        heading="Der Energieberater für deine Hosentasche"
                        content="Mit Energuide kannst du im Handumdrehen Daten deines Gebäudes erfassen und einen Verbrauchsausweis und/oder Bedarfsausweis erstellen."
                        selected={1}
                        total={scrollSnaps.length}
                    />
                    <Slide
                        heading="Schritt 1: Daten aufnehmen"
                        content="Der freundliche und schlaue Assistent hilft dir dabei die nötigen Daten im Handumdrehen einzugeben und beantwortet dabei all deine Fragen."
                        selected={2}
                        total={scrollSnaps.length}
                    />
                    <Slide
                        heading="Schritt 2: Energieausweise erstellen"
                        content="Wenn alle Daten vorliegen kannst du deinen Bedarfsausweis und deinen Verbrauchsausweis direkt herunterladen."
                        selected={3}
                        total={scrollSnaps.length}
                    />
                    <Slide
                        heading="Das ist erst der Anfang!"
                        content="Wir arbeiten fleissig daran, dass Energuide dir bald noch bei vielem anderen helfen kann. Er wird dir z.B. Maßnahmen vorschlagen können, um die Energieeffizienz deines Gebäudes zu steigern."
                        selected={4}
                        total={scrollSnaps.length}
                    />
                </div>
            </div>

            <div
                className="gradient-border-dark mx-auto flex w-full max-w-screen-md items-center justify-between border-t bg-background pb-8 pl-8
                    pr-8 pt-4 lg:bg-transparent"
            >
                <div className="flex items-center gap-4">
                    {scrollSnaps.map((_, index) => (
                        <div
                            key={index}
                            onClick={() => api?.scrollTo(index)}
                            className={cls(
                                "h-3 w-3 cursor-pointer rounded-full bg-text/15 transition-transform duration-200 hover:scale-125",
                                {
                                    "scale-110 bg-textStrong transition-transform duration-200":
                                        selectedIndex === index,
                                }
                            )}
                        />
                    ))}
                </div>

                {selectedIndex === scrollSnaps.length - 1 ? (
                    <LinkButton href="/auth/register-selection" variant="primary" onClick={onIntroDone}>
                        Start
                    </LinkButton>
                ) : (
                    <Link
                        to="/auth/register-selection"
                        className=" grid h-10 items-center text-link2 text-text underline"
                        onClick={onIntroDone}
                    >
                        Skip
                    </Link>
                )}
            </div>
        </div>
    )
}
