import { Haptics, ImpactStyle } from "@capacitor/haptics"
import * as ContextMenu from "@radix-ui/react-context-menu"
import { cls } from "@utils"
// import { isMobile } from "@utils"
// import { useState } from "react"
import { RoomContextMenu } from "."

export interface IListItem {
    title: string
    value: string
    onDelete?: () => void
    onEdit?: () => void
    onClick?: () => void
    className?: string
    icon?: React.ReactNode
}

function ListItem({ title, value, onDelete, onEdit, onClick, className, icon }: IListItem) {
    //TODO: Find a better alternative to edit the item on mobile

    return (
        <>
            <ContextMenu.Root onOpenChange={() => Haptics.impact({ style: ImpactStyle.Medium })}>
                <ContextMenu.Trigger>
                    <div
                        onClick={onClick}
                        className={cls(
                            "flex w-full select-none items-center justify-between rounded-xl bg-backgroundStrong p-4 font-label shadow-md",
                            className
                        )}
                    >
                        <div className="inline-block">
                            <p className="text-tiny font-normal text-text">{title}:</p>
                            <p className="text-body2 text-text">{value}</p>
                        </div>
                        <div onClick={onEdit} className="cursor-pointer text-white">
                            {icon}
                        </div>
                    </div>
                </ContextMenu.Trigger>

                <RoomContextMenu onDelete={onDelete} onEdit={onEdit} />
            </ContextMenu.Root>
        </>
    )
}
export { ListItem }
