import { baseUrl } from "@api/apiConfig"
import { z } from "zod"

export const ImageSchema = z.object({
    name: z.string().nullish(),
    url: z.string().nullish(),
    width: z.number().nullish(),
    height: z.number().nullish(),
})

export function transform(image: any) {
    return {
        ...image?.attributes,
        url: `${baseUrl}${image?.attributes?.url}`,
    }
}
