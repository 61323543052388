import { useAppState } from "@hooks/useState"

export function useAuthHeader() {
    const token = useAppState((state) => state.token)
    if (!token) {
        return null
    }

    const header: HeadersInit = {
        Authorization: `Bearer ${token}`,
    }

    return header
}
