import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useUserKey } from "@api/user/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type RemoveAvatarMutationParams = {
    userId: string
}

export function useRemoveUserAvatar() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: RemoveAvatarMutationParams) => {
            if (!authHeader) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/users/${params.userId}`, {
                    method: "put",
                    body: JSON.stringify({
                        avatar: {
                            set: [],
                        },
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gelöscht")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
