import React from "react"

export function useHeight(open: boolean) {
    const [height, setHeight] = React.useState<number | string>(open ? "auto" : 0)
    React.useLayoutEffect(() => {
        setHeight(open ? "auto" : 0)
    }, [open])

    return {
        height,
    }
}
