import { Capacitor, PluginListenerHandle } from "@capacitor/core"
import { Keyboard } from "@meerpohl/keyboard"
import React from "react"

export function useKeyboard() {
    const [open, setOpen] = React.useState(false)
    const [willOpenClose, setWillOpenClose] = React.useState(false)

    React.useEffect(() => {
        let showHandler: PluginListenerHandle | null = null
        let hideHandler: PluginListenerHandle | null = null
        let willShowHandler: PluginListenerHandle | null = null
        let willHideHandler: PluginListenerHandle | null = null

        if (Capacitor.isPluginAvailable("Keyboard")) {
            showHandler = Keyboard.addListener("keyboardDidShow", () => setOpen(true))
            hideHandler = Keyboard.addListener("keyboardDidHide", () => setOpen(false))
            willShowHandler = Keyboard.addListener("keyboardWillShow", () => setWillOpenClose(true))
            willHideHandler = Keyboard.addListener("keyboardWillHide", () => setWillOpenClose(false))
        }

        return () => {
            void showHandler?.remove()
            void hideHandler?.remove()
            void willShowHandler?.remove()
            void willHideHandler?.remove()
        }
    }, [])

    return {
        open,
        willOpenClose,
    }
}
