import { LoadingDots } from "@components/atoms/buttons"
import * as Avatar from "@radix-ui/react-avatar"
import { cls } from "@utils"
import React from "react"

type IImage = Avatar.AvatarProps & {
    src: string
    alt: string
    fallback: React.ReactNode
}

export function Image(props: IImage) {
    const { className, src, alt, fallback, ...avatarProps } = props
    const [loading, setLoading] = React.useState<boolean>(false)

    return (
        <Avatar.Root {...avatarProps} className={cls("relative block overflow-hidden text-body1", className)}>
            <Avatar.Image
                className="absolute bottom-0 left-0 right-0 top-0 h-full max-h-none w-full object-cover"
                src={src}
                alt={alt}
                onLoadingStatusChange={(status) => {
                    setLoading(status === "loading" || status === "idle")
                }}
                id="testing"
            />
            <Avatar.Fallback className="border-1 absolute inset-0 grid place-items-center border border-white/10">
                {loading ? <LoadingDots /> : fallback}
            </Avatar.Fallback>
        </Avatar.Root>
    )
}
