import { ICertificateType } from "@api/certificate/schema"
import { useCertificate } from "@api/certificate/useCertificate"
import { useCreateCertificate } from "@api/certificate/useCreateCertificate"
import { Button, LinkButton } from "@components/atoms/buttons"
import React from "react"

type ICertificateButton = {
    projectId: number
    type: ICertificateType
}

export function CertificateButton(props: ICertificateButton) {
    const { projectId, type } = props
    const { data } = useCertificate(projectId, type)
    const createCertificate = useCreateCertificate(projectId, type)
    const [disableCreate, setDisableCreate] = React.useState<boolean>(false)
    const [isCreating, setIsCreating] = React.useState<boolean>(false)

    if (isCreating) {
        return <Button variant="primary" disabled type="button" loading></Button>
    }

    if (!data || data.error) {
        return (
            <>
                {data?.error && (
                    <p className="rounded-xl border-2 border-secondaryAccent1 bg-solidTransparentWarning px-6 py-2 text-secondaryAccent1">
                        Es ist ein Fehler bei der Berechnung aufgetreten
                    </p>
                )}
                <Button
                    variant="primary"
                    onClick={async () => {
                        try {
                            setDisableCreate(true)
                            setIsCreating(true)
                            await createCertificate.mutateAsync()
                        } finally {
                            setDisableCreate(false)
                            setIsCreating(false)
                        }
                    }}
                    disabled={disableCreate}
                    type="button"
                >
                    Ausweis erstellen
                    <i className="ri-verified-badge-fill text-[1.5rem]"></i>
                </Button>
            </>
        )
    }

    if (!data.certificate && !data.error) {
        return (
            <Button variant="primary" disabled type="button">
                Ausweis wird bearbeitet . . .
            </Button>
        )
    }

    return (
        <LinkButton variant="primary" href={data.certificate?.url ?? ""} download isExternal>
            Ausweis herunterladen
        </LinkButton>
    )
}
