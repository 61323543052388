import { cls } from "@utils"
import { motion } from "framer-motion"
import { IEnerGuide } from "."

export function EnerGuide(props: IEnerGuide) {
    const { glow, className, energuideClassName: energuideClassName, ...rest } = props

    return (
        <div className={cls(className)}>
            <motion.div
                {...rest}
                className={cls("rounded-full", energuideClassName)}
                style={{
                    backgroundImage: "radial-gradient(#FFB130 0%, #F8766E 25%, #D182C9 50%, #A29BF7 80%)",
                    ...(glow
                        ? {
                              filter: "drop-shadow(0 0 20px #FF9BD999) drop-shadow(0 0 80px #A499F5DD)",
                          }
                        : {}),
                }}
            ></motion.div>
        </div>
    )
}
