import { ImageSchema } from "@api/image"
import { z } from "zod"

export const CertificateType = z.union([z.literal("demand"), z.literal("consumption")])

export type ICertificateType = z.infer<typeof CertificateType>

export const CertificateSchema = z.object({
    id: z.number(),
    taskId: z.string(),
    certificate: ImageSchema.nullish(),
    type: CertificateType,
    error: z.string().nullish(),
})

export type ICertificate = z.infer<typeof CertificateSchema>
