import { apiUrl, handleFetch, queryClient } from "@api/apiConfig"
import { useUserKey } from "@api/user/schema"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type AvatarMutationParams = {
    data: FormData
}

export function useMutateUserAvatar() {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: AvatarMutationParams) => {
            if (!authHeader) {
                return null
            }

            const { data } = params

            data.append("ref", "plugin::users-permissions.user")
            data.append("field", "avatar")

            return handleFetch(() =>
                fetch(`${apiUrl}/upload`, {
                    method: "post",
                    body: data,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gespeichert")
            await queryClient.invalidateQueries({
                queryKey: useUserKey,
            })
        },
    })
}
