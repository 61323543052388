import * as ToggleGroup from "@radix-ui/react-toggle-group"
import { cls } from "@utils"
import { AnimatePresence } from "framer-motion"
import { motion } from "framer-motion"
import React from "react"

type IChoiceInput = {
    choices: string[]
    onSelectionChanged?: (value: string) => void
    disabled: boolean
    className: string
}

export function ChoiceInput(props: IChoiceInput) {
    const { choices, onSelectionChanged, disabled, className } = props
    const [selected, setSelected] = React.useState<string>("")

    const onChanged = (value: string) => {
        setSelected(value)
        onSelectionChanged?.(value)
    }

    return (
        <AnimatePresence initial={false}>
            <ToggleGroup.Root
                type="single"
                orientation="vertical"
                value={selected}
                onValueChange={onChanged}
                className={cls("grid gap-4", className)}
                disabled={disabled}
            >
                {choices.map((choice, idx) => (
                    <motion.div
                        initial={{ y: "50%", opacity: 0 }}
                        animate={{ y: "0%", opacity: 1 }}
                        transition={{ delay: 0.2 * idx }}
                        className="grid"
                        key={choice}
                    >
                        <ToggleGroup.Item
                            value={choice}
                            className="min-h-10 rounded-lg border-[1px] border-primary bg-primary/10 px-4 py-2 font-label text-label text-primary transition
                                hover:bg-primary/20 data-[disabled]:border-none data-[disabled]:bg-backgroundDisabled data-[state=on]:bg-primary
                                data-[disabled]:text-textDisabled data-[state=on]:text-textStrong"
                        >
                            {choice}
                        </ToggleGroup.Item>
                    </motion.div>
                ))}
            </ToggleGroup.Root>
        </AnimatePresence>
    )
}
