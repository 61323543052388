import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area"
import React from "react"

export function useSafeArea() {
    const [safeArea, setSafeArea] = React.useState<SafeAreaInsets>({
        insets: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    })

    React.useEffect(() => {
        const getSafeArea = async () => {
            setSafeArea(await SafeArea.getSafeAreaInsets())
        }

        void getSafeArea()
    }, [])

    return safeArea.insets
}
