import { IUser } from "@api/user/schema.ts"
import { useMutateUser } from "@api/user/useMutateUser.ts"
import { Button } from "@components/atoms/buttons"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useAppState } from "@hooks/useState.ts"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import * as Form from "@radix-ui/react-form"
import { motion } from "framer-motion"
import React from "react"
import { useNavigate } from "react-router"
import { toast } from "sonner"

export function RegisterAdditions() {
    const form = React.useRef<HTMLFormElement | null>(null)
    const user = useAppState((state) => state.user)
    const setUser = useAppState((state) => state.setUser)
    const navigate = useNavigate()
    const userMutation = useMutateUser()
    const [loading, setLoading] = React.useState<boolean>(false)

    useTitlebar({
        title: "",
        mode: "hide",
        showContextMenu: false,
    })

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        try {
            event.preventDefault()
            setLoading(true)

            const formData = new FormData(event.currentTarget)
            userMutation.mutate(
                {
                    id: user?.id ?? 0,
                    data: formData,
                },
                {
                    onSuccess: (data) => {
                        if (data instanceof Error) {
                            throw data
                        }
                        setUser(data as IUser)
                        navigate("/authenticated/chat")
                    },
                }
            )
        } catch (error: any) {
            const { message } = error
            if (message) {
                toast.error(message)
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[25%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square justify-self-center w-[18rem] sm:w-[20rem] md:w-[30rem] lg:w-[22rem] xl:w-[25rem] 2xl:w-[40rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-end gap-12 p-8 pt-20 lg:content-center">
                <motion.div className="grid gap-4" layoutId="register-title">
                    <Heading level="h1">Nutzerprofil ergänzen</Heading>
                    <Text variant="body1" className="text-textLight">
                        Sag uns noch, wie du heißt, dann geht es los!
                    </Text>
                </motion.div>

                <Form.Root onSubmit={onSubmit} ref={form} className="grid gap-16">
                    <motion.div
                        transition={{
                            type: "tween",
                            ease: "circInOut",
                        }}
                        style={{ originX: 0.5, originY: 1 }}
                        initial={{
                            scaleY: 0.75,
                            transform: "translateY(25%)",
                            opacity: 0,
                        }}
                        animate={{
                            scaleY: 1,
                            transform: "translateY(0%)",
                            opacity: 1,
                        }}
                        className="grid gap-6"
                    >
                        <Input
                            name="displayName"
                            label="Name"
                            type="text"
                            required
                            messages={[
                                {
                                    content: "Name benötigt",
                                    match: "valueMissing",
                                },
                            ]}
                        />
                    </motion.div>
                    <Form.Submit asChild>
                        <Button variant="primary" loading={loading}>
                            Los geht's!
                        </Button>
                    </Form.Submit>
                </Form.Root>
            </div>
        </>
    )
}
