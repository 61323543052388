import { cls } from "@utils"
import { IGradientBorder, Radius, GradientBorder } from "."

type IGradientBackground = IGradientBorder & {
    className?: string
    radius: Radius
    onClick?: () => void
}

export function GradientBackground(props: IGradientBackground) {
    const { className, children, radius, onClick, ...rest } = props
    return (
        <GradientBorder radius={radius} {...rest}>
            <div
                className={cls("w-full overflow-hidden rounded-3xl bg-background", {
                    "rounded-lg": radius === "small",
                    "rounded-3xl": radius === "big",
                    "rounded-full": radius === "full",
                })}
            >
                <div
                    onClick={onClick}
                    className={cls(
                        "grid h-full w-full rounded-lg bg-gradient-to-b from-primaryAccent2/5 to-primaryAccent1/5 ",
                        className,
                        {
                            "cursor-pointer transition-all hover:bg-primaryAccent2/5": Boolean(onClick),
                        },
                        {
                            "rounded-lg": radius === "small",
                            "rounded-3xl": radius === "big",
                            "rounded-full": radius === "full",
                        }
                    )}
                >
                    {children}
                </div>
            </div>
        </GradientBorder>
    )
}
