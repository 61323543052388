import { Button } from "@components/atoms/buttons"
import { Input, IInput } from "@components/atoms/input"
import { Text } from "@components/atoms/typography.tsx"
import { UserAvatar } from "@components/molecules/avatar.tsx"
import { DataModal, IDataModal } from "@components/organisms/data-modal"
import { useImagePicker } from "@hooks/useImagePicker.ts"
import * as Form from "@radix-ui/react-form"
import { cls, getBlob } from "@utils"
import React from "react"

type Mode = "edit" | "create" | "delete" | "none"

export const icons: Record<Mode, string> = {
    edit: "ri-edit-fill",
    create: "ri-add-fill",
    delete: "ri-delete-bin-fill",
    none: "",
}

const buttonLabels: Record<Mode, string> = {
    edit: "Speichern",
    create: "Erstellen",
    delete: "Löschen",
    none: "",
}

type IChangeableField = React.PropsWithChildren<
    Omit<IDataModal, "iconClass" | "open" | "onOpenChange" | "trigger"> & {
        label: string
        display?: React.ReactNode
        mode: Mode
        className?: string
        onDelete?: () => Promise<void>
        onSubmit?: (data: FormData) => Promise<void>
    }
>

function ChangeableField(props: IChangeableField) {
    const { label, display, mode, title, description, children, className, onSubmit, onDelete } = props
    const [loading, setLoading] = React.useState(false)
    const [deleting, setDeleting] = React.useState(false)
    const [modalOpen, setModalOpen] = React.useState(false)

    const onSubmitForm: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target as HTMLFormElement)

        try {
            setLoading(true)
            await onSubmit?.(data)
            setModalOpen(false)
        } finally {
            setLoading(false)
        }
    }

    const onDeleteClick = async () => {
        try {
            setDeleting(true)
            await onDelete?.()
            setModalOpen(false)
        } finally {
            setDeleting(false)
        }
    }

    return (
        <div className="grid grid-cols-[minmax(0,1fr)_auto] items-end gap-4">
            <div className="grid gap-1 overflow-hidden">
                <Text variant="small" className="text-textVeryLight">
                    {label}
                </Text>
                {display}
            </div>

            <DataModal
                iconClass={icons[mode]}
                title={title}
                description={description}
                open={modalOpen}
                onOpenChange={setModalOpen}
                trigger={
                    <Button variant="freeform">
                        <i className={cls(icons[mode], "text-[1.5rem] leading-none text-textVeryLight")}></i>
                    </Button>
                }
            >
                <Form.Root className={cls("grid gap-6", className)} onSubmit={onSubmitForm}>
                    {children}
                    {onDelete ? (
                        <Button variant="warning" loading={deleting} type="button" onClick={onDeleteClick}>
                            Löschen
                        </Button>
                    ) : null}
                    {mode !== "none" ? (
                        <Button variant="primary" loading={loading}>
                            {buttonLabels[mode]}
                        </Button>
                    ) : null}
                </Form.Root>
            </DataModal>
        </div>
    )
}

type IChangeableText = {
    changeableFieldProps: Omit<IChangeableField, "children">
    inputProps: IInput
    onMutate?: (data: FormData) => Promise<void>
    onDelete?: () => Promise<void>
}

export function ChangeableText(props: IChangeableText) {
    const { changeableFieldProps, inputProps, onMutate, onDelete } = props
    const { display } = changeableFieldProps
    const { value } = inputProps

    const [inputValue, setInputValue] = React.useState<string>(`${value}`)
    const actualDisplay = display ?? value

    React.useEffect(() => {
        setInputValue(`${value}`)
    }, [value])

    const onSubmit = async (data: FormData) => {
        await onMutate?.(data)
    }

    return (
        <ChangeableField
            {...changeableFieldProps}
            display={actualDisplay ? actualDisplay : "---"}
            onSubmit={onSubmit}
            onDelete={onDelete}
        >
            <Input
                {...inputProps}
                label={changeableFieldProps.label}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
        </ChangeableField>
    )
}

type IChangeableImage = {
    changeableFieldProps: Pick<IChangeableField, "label" | "title" | "description" | "mode" | "className">
    entryId: number
    value?: string
    onMutate?: (data: FormData) => Promise<void>
    onDelete?: () => Promise<void>
}

export function ChangeableImage(props: IChangeableImage) {
    const { changeableFieldProps, entryId, value, onMutate, onDelete } = props
    const { photos, capture } = useImagePicker({ multiple: false })
    const photo = photos?.[0] ?? null
    const imagePath = photo?.webPath ?? value

    const onSubmit = async (data: FormData) => {
        data.append("files", await getBlob(photo?.webPath ?? ""), `avatar.${photo?.format ?? ""}`)
        await onMutate?.(data)
    }

    return (
        <ChangeableField
            {...changeableFieldProps}
            display={<UserAvatar src={imagePath ?? ""} alt={``} variant="chat" initials={"AV"} />}
            onSubmit={onSubmit}
            onDelete={onDelete}
            className="grid"
        >
            <input hidden name="refId" value={entryId} readOnly />

            <UserAvatar
                src={imagePath ?? ""}
                alt={``}
                variant="big"
                initials={"AV"}
                className="justify-self-center"
                onClick={capture}
                autoFocus
            />
        </ChangeableField>
    )
}

type IChangePassword = {
    onSubmit?: (data: FormData) => Promise<void>
}

export function ChangePassword(props: IChangePassword) {
    const { onSubmit } = props

    return (
        <ChangeableField
            label="Passwort"
            display="••••••"
            mode="edit"
            title="Passwort ändern"
            description="Um dein Passwort zu ändern, brauchen wir zunächst dein altes Passwort."
            onSubmit={onSubmit}
        >
            <Input
                label="Altes Passwort"
                name="currentPassword"
                type="password"
                required
                minLength={6}
                messages={[
                    {
                        content: "Mindestens 6 Zeichen benötigt",
                        match: "tooShort",
                    },
                    {
                        content: "Passwort benötigt",
                        match: "valueMissing",
                    },
                ]}
            />
            <Input
                label="Neues Passwort"
                name="password"
                type="password"
                minLength={6}
                messages={[
                    {
                        content: "Mindestens 6 Zeichen benötigt",
                        match: "tooShort",
                    },
                    {
                        content: "Passwort benötigt",
                        match: "valueMissing",
                    },
                ]}
            />
            <Input
                label="Neues Passwort bestätigen"
                name="passwordConfirmation"
                type="password"
                required
                minLength={6}
                messages={[
                    {
                        content: "Mindestens 6 Zeichen benötigt",
                        match: "tooShort",
                    },
                    {
                        content: "Passwort benötigt",
                        match: "valueMissing",
                    },
                    {
                        content: "Neue Passwörter sind nicht identisch",
                        match: (value, formData) => {
                            return value !== formData.get("password")
                        },
                    },
                ]}
            />
        </ChangeableField>
    )
}
