import { Button } from "@components/atoms/buttons"
import { useState } from "react"
import { toast } from "sonner"

interface IDialog {
    onClose: (value: string) => void
    title: string
    buttonText: string
    inputArgs?: { value: string; placeholder: string }
}

const Dialog = ({ onClose, title, buttonText, inputArgs }: IDialog) => {
    const [value, setValue] = useState(inputArgs?.value ?? "")

    function setRoomName(v: string) {
        if (v.length > 50) {
            toast.error("Der Raumname ist zu lang")
        } else {
            setValue(v)
        }
    }

    return (
        <>
            <div
                className="absolute left-1/2 top-1/2 h-min -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-[#F9F9F9] px-8 py-4
                    text-[black] shadow-lg sm:w-64 lg:w-96"
            >
                <h2 className="text-lg mx-4 mb-4 text-nowrap text-center font-semibold">{title}</h2>
                {inputArgs && (
                    <input
                        type="text"
                        className="mb-4 w-full rounded-lg border p-2"
                        placeholder={inputArgs.placeholder}
                        value={value}
                        onChange={(e) => setRoomName(e.target.value)}
                    />
                )}

                <div className="flex items-center justify-center">
                    <Button
                        className="bg-blue-500 rounded px-4 py-2"
                        onClick={() => onClose(value)}
                        variant={"secondary"}
                    >
                        {buttonText}
                    </Button>
                </div>
            </div>
        </>
    )
}
export default Dialog
