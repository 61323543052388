import { Button } from "@components/atoms/buttons"
import { GradientBackground } from "@components/atoms/gradient"
import { Heading } from "@components/atoms/typography"
import { useHeight } from "@hooks/useHeight"
import * as Collapsible from "@radix-ui/react-collapsible"
import { cls } from "@utils"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import { ChangeableText } from "./changeable-field"

type Entry = {
    label: string
    name: string
    value: string
}

type Section = {
    heading?: string
    entries: Entry[]
}

type ICollapsible = {
    title: string
    sections: Section[]
    onMutate?: (data: FormData) => Promise<void>
    defaultOpen?: boolean
}

export function DataBlock(props: ICollapsible) {
    const { title, sections, onMutate, defaultOpen } = props
    const [open, setOpen] = React.useState<boolean>(defaultOpen ?? false)
    const { height } = useHeight(open)

    return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
            <GradientBackground variant="dark" className="" radius="big">
                <Collapsible.Trigger asChild>
                    <Button variant="freeform" className="flex items-center justify-between p-4">
                        <Heading
                            level="h4"
                            className={cls("text-left", {
                                "!text-secondary": open,
                                "!text-text": !open,
                            })}
                        >
                            {title}
                        </Heading>
                        <motion.i
                            className={cls("ri-arrow-up-s-line inline-block text-[1.5rem] leading-none", {
                                "!text-secondary": open,
                                "!text-text": !open,
                            })}
                            animate={{
                                rotate: open ? 180 : 0,
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                            layout
                        ></motion.i>
                    </Button>
                </Collapsible.Trigger>
                <AnimatePresence initial={false}>
                    {open && (
                        <Collapsible.Content asChild forceMount>
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height }}
                                exit={{ height: 0 }}
                                transition={{ type: "tween" }}
                                className="overflow-hidden"
                            >
                                {sections.map(({ heading, entries }) => (
                                    <section className="grid gap-4 border-t-[1px] border-textDivider p-4" key={heading}>
                                        {heading ? <Heading level="h4">{heading}</Heading> : null}
                                        {entries.map(({ label, name, value }) => (
                                            <ChangeableText
                                                changeableFieldProps={{ mode: "edit", label }}
                                                inputProps={{ name, value }}
                                                onMutate={onMutate}
                                                key={name}
                                            />
                                        ))}
                                    </section>
                                ))}
                            </motion.div>
                        </Collapsible.Content>
                    )}
                </AnimatePresence>
            </GradientBackground>
        </Collapsible.Root>
    )
}
