import { handleFetch, apiUrl } from "@api/apiConfig"

export type IRegister = {
    email: string
    password: string
}

export async function register(params: IRegister) {
    const { email, password } = params
    await handleFetch(() =>
        fetch(`${apiUrl}/auth/local/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email,
                username: email,
                password,
            }),
        })
    )
}
