import { createContext, PropsWithChildren, useRef, useState } from "react"
import Overlay from "./overlay"

interface IOverlayContext {
    open: (children: React.JSX.Element) => Promise<unknown>
    close: (result?: unknown) => void
}

const OverlayContext = createContext<IOverlayContext>({
    open: () => new Promise(() => {}),
    close: () => {},
})

function OverlayProvider({ children }: PropsWithChildren) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [content, setContent] = useState<React.ReactNode | null>(null)
    const [className, setClassName] = useState<string>("")
    const resolvePromiseRef = useRef<(value: unknown) => void>(() => {})

    const open = (content: JSX.Element, options?: { className?: string }): Promise<unknown> => {
        return new Promise<unknown>((resolve) => {
            setContent(content)
            setIsOpen(true)
            if (options?.className) setClassName(options.className)
            resolvePromiseRef.current = resolve
        })
    }

    const close = (result?: unknown) => {
        setIsOpen(false)
        setTimeout(() => {
            setContent(null)
            setClassName("")
            resolvePromiseRef.current(result)
        }, 300) // 300ms matches the CSS transition duration
    }
    return (
        <OverlayContext.Provider value={{ open, close }}>
            <Overlay isOpen={isOpen} className={className}>
                {content}
            </Overlay>
            {children}
        </OverlayContext.Provider>
    )
}

export default OverlayProvider
export { OverlayContext }
