import { useDevicePlatform } from "@hooks/useDevicePlatform"
import { useKeyboard } from "@hooks/useKeyboard"
import { useSafeArea } from "@hooks/useSafeArea"
import { useAppState } from "@hooks/useState"
import { cls } from "@utils"
import { PropsWithChildren } from "react"

type IContentOffset = PropsWithChildren<{
    className?: string
    offsetAppbar: boolean
    safeAreas: boolean
}>

export function ContentOffset(props: IContentOffset) {
    const { children, className, offsetAppbar, safeAreas } = props
    const inset = useSafeArea()
    const appbarSize = useAppState((state) => state.appbarSize)
    const { willOpenClose } = useKeyboard()
    const { isAndroid } = useDevicePlatform()

    return (
        <div
            className={cls(className)}
            style={{
                ...(safeAreas || offsetAppbar
                    ? {
                          paddingTop: `calc(${offsetAppbar ? appbarSize[1] : 0}px + ${safeAreas ? inset.top : 0}px)`,
                      }
                    : {}),
                ...(!isAndroid() && !willOpenClose && safeAreas && inset.bottom > 0
                    ? { paddingBottom: `${inset.bottom}px` }
                    : {}),
            }}
        >
            {children}
        </div>
    )
}
