import { IUser } from "@api/user/schema"
import { Preferences } from "@capacitor/preferences"
import { IContextMenu } from "@components/molecules/context-menu"
import { create } from "zustand"
import { persist, createJSONStorage, StateStorage } from "zustand/middleware"

const CapacitorStorage: StateStorage = {
    async getItem(name) {
        const result = await Preferences.get({
            key: name,
        })
        return result.value
    },

    async setItem(name, value) {
        await Preferences.set({
            key: name,
            value: value,
        })
    },

    async removeItem(name) {
        await Preferences.remove({
            key: name,
        })
    },
}

type ChatMessage = {
    author: string
    timestamp: number
    message: string
    attachment?: string
}

type AppState = {
    hasSeenIntro: boolean
    setHasSeenIntro: () => void

    token: string
    user: IUser | null
    setAuthentication: (token: string, user: IUser | null) => void
    setUser: (user: IUser | null) => void
    clearAuth: () => void

    appbarSize: number[]
    setAppbarSize: (size: number[]) => void

    title: string
    setTitle: (title: string) => void

    backTo: string
    setBackTo: (backTo: string) => void

    mode: "menu" | "back" | "hide"
    setMode: (mode: "menu" | "back" | "hide") => void

    showSearchIcon: boolean
    setShowSearchIcon: (show: boolean) => void
    searchOpen: boolean
    setSearchOpen: (open: boolean) => void

    showContextMenu: boolean
    contextMenuProps: IContextMenu
    setShowContextMenu: (show: boolean, contextMenuProps?: IContextMenu) => void

    activeProjectId: number | null
    setActiveProject: (id: number | null) => void

    chatMessages: ChatMessage[]
    clearChatMessages: () => void
    addChatMessages: (chatMessages: ChatMessage[]) => void

    isHydrated: boolean
    setIsHydrated: () => void
}

export const useAppState = create<AppState>()(
    persist(
        (set) => ({
            hasSeenIntro: false,
            setHasSeenIntro() {
                set(() => ({
                    hasSeenIntro: true,
                }))
            },

            token: "",
            user: null,
            setAuthentication(token: string, user: IUser | null) {
                set(() => ({
                    token,
                    user,
                }))
            },
            setUser(user: IUser | null) {
                set(() => ({
                    user,
                }))
            },
            clearAuth() {
                set(() => ({
                    token: "",
                    user: null,
                    activeProjectId: null,
                }))
            },

            appbarSize: [0, 0],
            setAppbarSize(size: number[]) {
                set(() => ({ appbarSize: size }))
            },

            title: "",
            setTitle(title: string) {
                set(() => ({ title }))
            },

            backTo: "",
            setBackTo(backTo: string) {
                set(() => ({
                    backTo,
                    mode: "back",
                }))
            },

            mode: "menu",
            setMode(mode: "back" | "menu" | "hide") {
                set(() => ({ mode }))
            },

            showSearchIcon: false,
            setShowSearchIcon(show) {
                set(() => ({ showSearchIcon: show }))
            },
            searchOpen: false,
            setSearchOpen(open) {
                set(() => ({ searchOpen: open }))
            },

            showContextMenu: false,
            contextMenuProps: {
                showDataExport: false,
                showProjectOverview: false,
                showProjectSettings: false,
            },
            setShowContextMenu(show, contextMenuProps) {
                set(() => ({ showContextMenu: show, contextMenuProps }))
            },

            activeProjectId: null,
            setActiveProject(id) {
                set(() => ({ activeProjectId: id }))
            },

            chatMessages: [],
            clearChatMessages() {
                set(() => ({
                    chatMessages: [],
                }))
            },
            addChatMessages(chatMessages: ChatMessage[]) {
                set((state) => ({
                    chatMessages: [...state.chatMessages, ...chatMessages],
                }))
            },

            isHydrated: false,
            setIsHydrated() {
                set(() => ({ isHydrated: true }))
            },
        }),
        {
            name: "energuide-preferences",
            storage: createJSONStorage(() => CapacitorStorage),
            onRehydrateStorage: () => (state) => {
                state?.setIsHydrated()
            },
        }
    )
)
