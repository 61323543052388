import { Photo } from "@capacitor/camera"
import { Button } from "@components/atoms/buttons"
import { GradientBorder } from "@components/atoms/gradient"
import { PreviewImage } from "@components/molecules/preview-image"
import { useHeight } from "@hooks/useHeight"
import * as Form from "@radix-ui/react-form"
import { AnimatePresence, motion } from "framer-motion"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"

type IChatInput = {
    photos: Photo[]
    onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>
    onRemoveImage?: (id: string) => void
    onCaptureImage?: () => void
}

export const ChatInput = React.forwardRef<HTMLTextAreaElement, IChatInput>((props, ref) => {
    const { photos, onKeyDown, onRemoveImage, onCaptureImage } = props
    const { height } = useHeight(photos.length > 0)

    return (
        <GradientBorder variant="light" radius="big">
            <Form.Field
                name="chat"
                className="grid grid-cols-auto-1fr-auto items-center rounded-3xl bg-backgroundStrong py-2"
            >
                <div className="col-span-3 flex gap-2 px-5">
                    <AnimatePresence initial={false}>
                        {photos.map((photo) => (
                            <motion.div
                                initial={{ height: 0, opacity: 0 }}
                                animate={{ height, opacity: 1 }}
                                exit={{ height: 0, opacity: 0 }}
                                transition={{ type: "tween" }}
                                className="overflow-hidden"
                                key={photo.webPath!}
                            >
                                <PreviewImage
                                    alt={"Ausgewähltes Bild für Upload"}
                                    path={photo.webPath!}
                                    onRemove={() => onRemoveImage?.(photo.webPath!)}
                                    className="pb-2 pr-3 pt-3"
                                />
                            </motion.div>
                        ))}
                    </AnimatePresence>
                </div>
                <Button variant="tertiary" type="button" onClick={onCaptureImage}>
                    <i className="ri-attachment-line text-[1.5rem]"></i>
                </Button>
                <Form.Control
                    asChild
                    className="h-28 resize-none overflow-hidden bg-transparent text-body2 text-primaryAccent2 focus-within:outline-none
                        focus-visible:outline-none"
                >
                    <TextareaAutosize
                        autoCapitalize="off"
                        autoCorrect="off"
                        maxRows={5}
                        onKeyDown={onKeyDown}
                        ref={ref}
                    />
                </Form.Control>
                <Button variant="tertiary">
                    <i className="ri-send-plane-2-fill text-[1.5rem]"></i>
                </Button>
            </Form.Field>
        </GradientBorder>
    )
})
