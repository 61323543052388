import { GradientBackground } from "@components/atoms/gradient"
import { Text } from "@components/atoms/typography"
import { cls } from "@utils"

type IBigStat = {
    iconClass: string
    name: string
    value: string
    unit: string
}

export function BigStat(props: IBigStat) {
    const { iconClass, name, value, unit } = props
    return (
        <GradientBackground variant="dark" className="gap-4 p-4" radius="big">
            <div className="grid grid-cols-[auto_1fr] items-end gap-2">
                <i className={cls(iconClass, "text-[1.5rem] leading-none text-text")}></i>
                <Text variant="strong2" className="text-text">
                    {name}
                </Text>
            </div>

            <div className="grid">
                <span className=" font-heading text-[3rem] font-semibold leading-none text-secondary">{value}</span>
                <Text variant="body2" className="text-textLight">
                    {unit}
                </Text>
            </div>
        </GradientBackground>
    )
}
