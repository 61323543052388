import { apiUrl, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useAppState } from "@hooks/useState"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router"
import { toast } from "sonner"

export function useRemoveUser() {
    const authHeader = useAuthHeader()
    const userId = useAppState((state) => state.user?.id)
    const clearAuth = useAppState((state) => state.clearAuth)
    const navigate = useNavigate()

    return useMutation({
        mutationFn: async () => {
            if (!authHeader || !userId) {
                return null
            }

            return handleFetch(() =>
                fetch(`${apiUrl}/users/${userId}`, {
                    method: "delete",
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            clearAuth()
            navigate("/auth/login-selection")
            toast.success("Account gelöscht")
        },
    })
}
