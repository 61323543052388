import { handleFetch, apiUrl } from "@api/apiConfig"

export type IReset = {
    code: string
    password: string
}

export async function resetPassword(params: IReset) {
    const { code, password } = params
    await handleFetch(() =>
        fetch(`${apiUrl}/auth/reset-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                code,
                password,
                passwordConfirmation: password,
            }),
        })
    )
}
