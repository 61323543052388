import { ImageSchema } from "@api/image"
import { z } from "zod"

export const UserSchema = z.object({
    id: z.number(),
    email: z.string(),
    pendingEmail: z.string().nullish(),
    displayName: z.string().nullish(),
    provider: z.string(),
    confirmed: z.boolean(),
    avatar: ImageSchema.nullish(),
    isDibtUser: z.boolean(),
    dibtUsername: z.string().nullable(),
    dibtPassword: z.string().nullable(),
})

export type IUser = z.infer<typeof UserSchema>

export const useUserKey = ["users", "me"]
