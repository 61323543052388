import { apiUrl, queryClient, handleFetch } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useMutation } from "@tanstack/react-query"
import { toast } from "sonner"

type ProjectCoverMutationParams = {
    data: FormData
}

export function useMutateProjectCover(id: number) {
    const authHeader = useAuthHeader()
    return useMutation({
        mutationFn: async (params: ProjectCoverMutationParams) => {
            if (!authHeader || !id) {
                return null
            }

            const { data } = params

            data.append("ref", "api::project.project")
            data.append("field", "cover")

            return handleFetch(() =>
                fetch(`${apiUrl}/upload`, {
                    method: "post",
                    body: data,
                    headers: {
                        ...authHeader,
                    },
                })
            )
        },
        onSuccess: async () => {
            toast.success("Gespeichert")
            await queryClient.invalidateQueries({
                queryKey: ["project", id],
            })
        },
    })
}
