import { IReset, resetPassword } from "@api/auth/resetPassword.ts"
import { Button } from "@components/atoms/buttons"
import { EnerGuide, EnerGuideDark } from "@components/atoms/energuide"
import { Input } from "@components/atoms/input.tsx"
import { Heading, Text } from "@components/atoms/typography.tsx"
import { useTitlebar } from "@hooks/useTitlebar.ts"
import { Footer } from "@layouts/auth.tsx"
import * as Form from "@radix-ui/react-form"
import { motion } from "framer-motion"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "sonner"

export function ResetPassword() {
    const form = React.useRef<HTMLFormElement | null>(null)
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [code, setCode] = React.useState<string>("")

    useTitlebar({
        title: "",
        mode: "back",
        backTo: "/auth/login",
        showContextMenu: false,
    })

    React.useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        setCode(queryParameters.get("code") ?? "")
    }, [])

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()

        if (!form.current) {
            return
        }

        try {
            setLoading(true)
            const formData = new FormData(form.current)
            const resetData = Object.fromEntries(formData) as IReset

            await resetPassword(resetData)
            form.current?.reset()

            navigate("/auth/login")
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <EnerGuideDark
                className="absolute inset-0 z-[-1] justify-self-center overflow-hidden"
                energuideClassName="absolute left-[-75%] top-[25%] z-[-1] aspect-square w-[150%]"
                layoutId="energuide-dark"
            />
            <EnerGuide
                glow
                className="absolute inset-0 z-[-1] grid overflow-hidden"
                energuideClassName="absolute top-[-12%] lg:top-[-22%] z-[-1] aspect-square w-2/3 justify-self-center max-w-[30rem]"
                layoutId="energuide"
            />

            <div className="mx-auto grid min-h-full w-full max-w-screen-sm content-end gap-12 px-8 pt-20 lg:content-center">
                <motion.div className="grid gap-4">
                    <Heading level="h1">Passwort zurücksetzen</Heading>
                    <Text variant="body1" className="text-textLight">
                        Du hast dein Passwort vergessen. Das kann passieren. Gib hier dein neues Passwort ein, dann geht
                        es weiter.
                    </Text>
                </motion.div>

                <Form.Root onSubmit={onSubmit} ref={form} className="grid gap-16">
                    <motion.div
                        transition={{
                            type: "tween",
                        }}
                        style={{ originX: 0.5, originY: 1 }}
                        initial={{
                            scaleY: 0.75,
                            transform: "translateY(25%)",
                            opacity: 0,
                        }}
                        animate={{
                            scaleY: 1,
                            transform: "translateY(0%)",
                            opacity: 1,
                        }}
                        className="grid gap-6"
                    >
                        <input hidden name="code" value={code} />
                        <Input
                            name="password"
                            label="Passwort"
                            type="password"
                            required
                            minLength={6}
                            messages={[
                                {
                                    content: "Mindestens 6 Zeichen benötigt",
                                    match: "tooShort",
                                },
                                {
                                    content: "Passwort benötigt",
                                    match: "valueMissing",
                                },
                            ]}
                        />
                    </motion.div>
                    <Form.Submit asChild>
                        <Button variant="primary" loading={loading}>
                            Passwort zurücksetzen
                        </Button>
                    </Form.Submit>
                </Form.Root>

                <Footer>
                    <Text variant="small" className="text-textLight">
                        Ist dir doch wieder eingefallen?
                    </Text>
                    <Link to="/auth/login-selection" className="text-white underline">
                        Login
                    </Link>
                </Footer>
            </div>
        </>
    )
}
