import * as ScrollArea from "@radix-ui/react-scroll-area"
import { cls } from "@utils"
import React, { CSSProperties } from "react"

type IScrollable = React.PropsWithChildren<{
    className?: string
    style?: CSSProperties
    viewportClassName?: string
}>

export const Scrollable = React.forwardRef<HTMLDivElement, IScrollable>((props, ref) => {
    const { className, style, children, viewportClassName } = props

    return (
        <ScrollArea.Root className={cls("overscroll-none", className)} style={style}>
            <ScrollArea.Viewport className={cls("h-full overscroll-none", viewportClassName)} ref={ref}>
                {children}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar className="" orientation="vertical">
                <ScrollArea.Thumb className="" />
            </ScrollArea.Scrollbar>
            <ScrollArea.Corner className="" />
        </ScrollArea.Root>
    )
})
