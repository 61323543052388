import { apiUrl, handleFetch, handleParse } from "@api/apiConfig"
import { useAuthHeader } from "@hooks/useAuthHeader"
import { useQuery } from "@tanstack/react-query"
import { IKpis, KpisSchema } from "./schema"

function transform(kpis: any): IKpis {
    return kpis.attributes
}

type IUseKpis = {
    enabled?: boolean
}

export function useKpis(projectId: number | null, params?: IUseKpis) {
    const { enabled = true } = params ?? {}
    const authHeader = useAuthHeader()

    return useQuery({
        queryKey: ["kpis", projectId],
        queryFn: async () => {
            const { data } = await handleFetch(() =>
                fetch(`${apiUrl}/project/kpis?projectId=${projectId}`, {
                    headers: {
                        ...authHeader,
                    },
                })
            )

            const kpis = handleParse(() => KpisSchema.parse(transform(data)))
            return kpis
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!enabled && !!authHeader && !!projectId,
    })
}
