import { z } from "zod"

export const FaqHintSchema = z.object({
    id: z.number(),
    question: z.string(),
})

export type IFaqHint = z.infer<typeof FaqHintSchema>

export const FaqHintsSchema = z.array(FaqHintSchema)

export type IFaqHints = z.infer<typeof FaqHintsSchema>
