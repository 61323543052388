import { Photo, Camera, CameraResultType } from "@capacitor/camera"
import React from "react"

type IUseImagePicker = {
    multiple?: boolean
}

export function useImagePicker(params?: IUseImagePicker) {
    const { multiple = false } = params ?? {}

    const [photos, setPhotos] = React.useState<Photo[]>([])

    const capture = async () => {
        const photo = await Camera.getPhoto({
            quality: 90,
            allowEditing: true,
            resultType: CameraResultType.Uri,
            webUseInput: true,
        })

        if (photo) {
            if (multiple) {
                setPhotos([...photos, photo])
            } else {
                setPhotos([photo])
            }
        }
    }

    const reset = () => {
        setPhotos([])
    }

    const remove = (path: string) => {
        setPhotos(photos.filter((photo) => photo.webPath !== path))
    }

    return {
        capture,
        reset,
        remove,
        photos,
    }
}
